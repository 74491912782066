import { generateMapByOpts } from '@/common/utils'
// 房屋类型：0未知、1公寓、2排屋、3别墅、4商铺、5办公用房、6经营用房、21保姆房、22储藏室、23自行车库、24车库、25车位、26其他
// 房屋类型
const houseTypeOps = [
    {
      text: '未知',
      value: 0
    },
    {
      text: '公寓',
      value: 1
    },
    {
      text: '排屋',
      value: 2
    },
    {
      text: '别墅',
      value: 3
    },
    {
      text: '商铺',
      value: 4
    },
    {
      text: '办公用房',
      value: 5
    },
    {
      text: '经营用房',
      value: 6
    },
    {
      text: '住宅',
      value: 7
    },
    {
      text: '保姆房',
      value: 21
    },
    {
      text: '储藏室',
      value: 22
    },
    {
      text: '自行车库',
      value: 23
    },
    {
      text: '车库',
      value: 24
    },
    {
      text: '车位',
      value: 25
    },
    {
      text: '其他',
      value: 26
    }
]

// 房间状态：1空置(未出售)、2未领、3空关、4装修、5入住、6入伙、10可售，11已售， 12已预定，13限制房产
const roomStatusOps = [
  {
    text:'空置',
    value:1
  },
  {
    text:'未领',
    value:2
  },
  {
    text:'空关',
    value:3
  },
  {
    text:'装修',
    value:4
  },
  {
    text:'入住',
    value:5
  },
  {
    text:'入伙',
    value:6
  },
  {
    text:'可售',
    value:10
  },
  {
    text:'已售',
    value:11
  },
  {
    text:'已预定',
    value:12
  },
  {
    text:'限制房产',
    value:13
  }
]

// 与产权人关系1业主 2家属 3租客 4伙伴 5嘉宾
const userTypeOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text:'业主',
    value:1
  },
  {
    text:'家属',
    value:2
  },
  {
    text:'租客',
    value:3
  },
  {
    text:'伙伴',
    value:4
  },
  {
    text:'嘉宾',
    value:5
  },
]
// 婚姻状况
const maritalStatusOpts = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '单身',
    value: '0'
  },
  {
    text: '已婚',
    value: '1'
  },
  {
    text: '离异',
    value: '2'
  },
  {
    text: '丧偶',
    value: '3'
  }
]
// 证件类型
const cardTypeOpts = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '身份证',
    value: 1
  },
  {
    text: '军官证',
    value: 2
  },
  {
    text: '护照',
    value: 3
  },
  {
    text: '驾驶证',
    value: 5
  },
  {
    text: '港澳通行证',
    value: 6
  },
  {
    text: '台胞证',
    value: 7
  },
  {
    text: '户口本',
    value: 8
  },
  {
    text: '警官证',
    value: 9
  },
  {
    text: '其他',
    value: 4
  },
]

// 政治面貌
const politicsStatusOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text:'中共党员',
    value:'中共党员'
  },
  {
    text:'共青团员',
    value:'共青团员'
  },
  {
    text:'群众',
    value:'群众'
  }
]
// 订单状态
const orderStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待接收',
    value: 1
  },
  {
    text: '待处理',
    value: 2
  },
  {
    text: '超时未接收',
    value: 3
  },
  {
    text: '超时未处理',
    value: 4
  },
  {
    text: '处理中',
    value: 5
  },
  {
    text: '已处理',
    value: 6
  },
  {
    text: '已完成',
    value: 7
  },
  {
    text: '已关闭',
    value: 8
  },
  {
    text: '超时处理中',
    value: 9
  }
]

const userTypeMap = generateMapByOpts(userTypeOps)
const maritalStatusMap = generateMapByOpts(maritalStatusOpts)
const cardTypeMap = generateMapByOpts(cardTypeOpts)
const houseTypeMap = generateMapByOpts(houseTypeOps)
const orderStatusMap = generateMapByOpts(orderStatusOps)
export {
    houseTypeOps,
    roomStatusOps,
    userTypeOps,
    maritalStatusOpts,
    cardTypeOpts,
    politicsStatusOps,
    userTypeMap,
    maritalStatusMap,
    cardTypeMap,
    houseTypeMap,
    orderStatusMap
}
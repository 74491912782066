var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "payFeeDialog" },
    [
      _c(
        "col2-detail",
        [
          _c(
            "col2-block",
            [
              _vm.billType == 0
                ? [
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("账单数量:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", { staticClass: "edit-bill-text" }, [
                            _vm._v(_vm._s(_vm.selectBillList.length)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("账单总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [_vm._v(_vm._s(_vm.selectTotalMoney))]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("违约金总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [_vm._v(_vm._s(_vm.selectLateFee))]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("历史优惠总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [_vm._v(_vm._s(_vm.selectDiscountMoney))]),
                        ]),
                      ]),
                    ]),
                  ]
                : [
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("账单数量:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", { staticClass: "edit-bill-text" }, [
                            _vm._v(_vm._s(_vm.partBillTotalNumber)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("账单总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPreBillMoney[
                                  _vm.selectedTotal + "TotalMoney"
                                ] &&
                                  _vm.totalPreBillMoney[
                                    _vm.selectedTotal + "TotalMoney"
                                  ].toFixed(2)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("违约金总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPreBillMoney[
                                  _vm.selectedTotal + "LateFee"
                                ] &&
                                  _vm.totalPreBillMoney[
                                    _vm.selectedTotal + "LateFee"
                                  ].toFixed(2)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("历史优惠总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPreBillMoney[
                                  _vm.selectedTotal + "DiscountMoney"
                                ] &&
                                  _vm.totalPreBillMoney[
                                    _vm.selectedTotal + "DiscountMoney"
                                  ].toFixed(2)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
              _c("el-col", [
                _c(
                  "div",
                  {
                    staticClass: "edit-bill-dialog-content",
                    staticStyle: { "justify-content": "flex-start" },
                  },
                  [
                    _c("div", { staticClass: "edit-bill-tips" }, [
                      _vm._v("缴费形式:"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "edit-bill-dialog-input",
                        attrs: { div: "" },
                      },
                      [
                        _c("v-select", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            options: _vm.billTypeOpts,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.billType,
                            callback: function ($$v) {
                              _vm.billType = $$v
                            },
                            expression: "billType",
                          },
                        }),
                        _vm.billType == 1
                          ? [
                              _c("v-input-number", {
                                attrs: {
                                  label: "",
                                  placeholder: "请输入金额",
                                  precision: 2,
                                },
                                model: {
                                  value: _vm.prebillMoney,
                                  callback: function ($$v) {
                                    _vm.prebillMoney = $$v
                                  },
                                  expression: "prebillMoney",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _c("el-col", [
                _vm.billType == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "edit-bill-dialog-content",
                        staticStyle: {
                          "justify-content": "flex-start",
                          "padding-left": "60px",
                          "padding-top": "35px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "max-height": "188px",
                              overflow: "auto",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  width: "1000px",
                                },
                              },
                              _vm._l(
                                _vm.selectBillList,
                                function (bill, index) {
                                  return _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        height: "90px",
                                        position: "relative",
                                        "border-top": "2px solid #DCDFE6",
                                        "border-bottom": "2px solid #DCDFE6",
                                      },
                                    },
                                    [
                                      index <= _vm.preBillInfo.index
                                        ? _c("div", {
                                            staticStyle: {
                                              "box-sizing": "border-box",
                                              position: "absolute",
                                              bottom: "0",
                                              left: "0",
                                              width: "100px",
                                              height: "90px",
                                              background: "#E4EFFF",
                                              "border-bottom":
                                                "2px solid #2077FF",
                                            },
                                          })
                                        : index === _vm.preBillInfo.index + 1
                                        ? _c("div", {
                                            staticStyle: {
                                              "box-sizing": "border-box",
                                              position: "absolute",
                                              bottom: "0",
                                              left: "0",
                                              height: "90px",
                                              background: "#E4EFFF",
                                              "border-bottom":
                                                "2px solid #2077FF",
                                            },
                                            style: {
                                              width:
                                                _vm.halfBillInfo.percent + "%",
                                            },
                                          })
                                        : _c("div", {
                                            staticStyle: {
                                              "box-sizing": "border-box",
                                              position: "absolute",
                                              top: "0",
                                              left: "0",
                                              width: "100%",
                                              height: "90px",
                                            },
                                          }),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            trigger: "hover",
                                            title: "账单明细",
                                            "open-delay": 300,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "reference",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          position: "relative",
                                                          "white-space":
                                                            "nowrap",
                                                          "text-overflow":
                                                            "ellipsis",
                                                          width: "100px",
                                                          height: "90px",
                                                          cursor: "pointer",
                                                          padding: "5px",
                                                          "box-sizing":
                                                            "border-box",
                                                          overflow: "hidden",
                                                          "font-size": "10px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.85)",
                                                              color: "#666",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  bill.billingStartTime.split(
                                                                    " "
                                                                  )[0]
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.85)",
                                                              color: "#666",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "  ~ " +
                                                                _vm._s(
                                                                  bill.billingEndTime.split(
                                                                    " "
                                                                  )[0]
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "padding-top":
                                                                "10px",
                                                              "font-size":
                                                                "14px",
                                                              width: "100%",
                                                              overflow:
                                                                "hidden",
                                                              "text-overflow":
                                                                "ellipsis",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "  ￥" +
                                                                _vm._s(
                                                                  bill.billMoney
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _vm._v(
                                                "\n                      账单周期：" +
                                                  _vm._s(
                                                    bill.billingStartTime.split(
                                                      " "
                                                    )[0]
                                                  ) +
                                                  " ~ " +
                                                  _vm._s(
                                                    bill.billingEndTime.split(
                                                      " "
                                                    )[0]
                                                  ) +
                                                  " "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                      账单金额：￥" +
                                                  _vm._s(bill.totalMoney) +
                                                  " "
                                              ),
                                              _c("br"),
                                              bill.lateFreeVisible == 1
                                                ? [
                                                    _vm._v(
                                                      "\n                        违约金：￥" +
                                                        _vm._s(bill.lateFee) +
                                                        " "
                                                    ),
                                                    _c("br"),
                                                  ]
                                                : _vm._e(),
                                              Number(bill.discountMoney) > 0
                                                ? [
                                                    _vm._v(
                                                      "\n                        账单折扣：￥" +
                                                        _vm._s(
                                                          bill.discountMoney
                                                        )
                                                    ),
                                                    _c("br"),
                                                  ]
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                      应收金额：￥" +
                                                  _vm._s(bill.billMoney) +
                                                  "\n                    "
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("el-col", [
                _vm.billType == 1 && _vm.prebillMoney > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "justify-content": "flex-start",
                          "padding-left": "60px",
                          "text-align": "left",
                          "padding-bottom": "20px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#909399",
                              "font-size": "12px",
                              "padding-top": "24px",
                            },
                          },
                          [_vm._v("请选择一个时间段账单")]
                        ),
                        _vm.billType == 1
                          ? _c(
                              "div",
                              { staticClass: "edit-bill-dialog-content" },
                              [
                                _vm.totalPreBillMoney.minMoney !=
                                _vm.totalPreBillMoney.maxMoney
                                  ? [
                                      Number(_vm.totalPreBillMoney.minMoney) !=
                                      0
                                        ? _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: _vm.selectedTotal,
                                                callback: function ($$v) {
                                                  _vm.selectedTotal = $$v
                                                },
                                                expression: "selectedTotal",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  staticStyle: {
                                                    height: "55px",
                                                    display: "inline-flex",
                                                    "justify-content": "center",
                                                    "flex-direction": "column",
                                                  },
                                                  attrs: {
                                                    border: "",
                                                    label: "min",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        width: "120px",
                                                        "white-space": "normal",
                                                        "text-algin": "left",
                                                        "font-size": "12px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bolder",
                                                            "font-size": "16px",
                                                            "padding-bottom":
                                                              "6px",
                                                            color: "black",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "￥" +
                                                              _vm._s(
                                                                _vm
                                                                  .totalPreBillMoney
                                                                  .minMoney
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#909399",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    缴至" +
                                                              _vm._s(
                                                                _vm
                                                                  .totalPreBillMoney
                                                                  .minEndDay
                                                              ) +
                                                              "            \n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  staticStyle: {
                                                    height: "55px",
                                                    display: "inline-flex",
                                                    "justify-content": "center",
                                                    "flex-direction": "column",
                                                  },
                                                  attrs: {
                                                    border: "",
                                                    label: "max",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        width: "120px",
                                                        "white-space": "normal",
                                                        "text-algin": "left",
                                                        "font-size": "12px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bolder",
                                                            "font-size": "16px",
                                                            "padding-bottom":
                                                              "6px",
                                                            color: "black",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "￥" +
                                                              _vm._s(
                                                                _vm
                                                                  .totalPreBillMoney
                                                                  .maxMoney
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#909399",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    缴至" +
                                                              _vm._s(
                                                                _vm
                                                                  .totalPreBillMoney
                                                                  .maxEndDay
                                                              ) +
                                                              "             \n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "el-radio-group",
                                            {
                                              attrs: { readonly: "" },
                                              model: {
                                                value: _vm.selectedTotal,
                                                callback: function ($$v) {
                                                  _vm.selectedTotal = $$v
                                                },
                                                expression: "selectedTotal",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  staticStyle: {
                                                    height: "55px",
                                                    display: "inline-flex",
                                                    "justify-content": "center",
                                                    "flex-direction": "column",
                                                  },
                                                  attrs: {
                                                    border: "",
                                                    label: "max",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        width: "120px",
                                                        "white-space": "normal",
                                                        "text-algin": "left",
                                                        "font-size": "12px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bolder",
                                                            "font-size": "16px",
                                                            "padding-bottom":
                                                              "6px",
                                                            color: "black",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "￥" +
                                                              _vm._s(
                                                                _vm
                                                                  .totalPreBillMoney
                                                                  .maxMoney
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#909399",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  缴至" +
                                                              _vm._s(
                                                                _vm
                                                                  .totalPreBillMoney
                                                                  .maxEndDay
                                                              ) +
                                                              "            \n                "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  : Number(_vm.totalPreBillMoney.minMoney) != 0
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        attrs: { readonly: "" },
                                        model: {
                                          value: _vm.selectedTotal,
                                          callback: function ($$v) {
                                            _vm.selectedTotal = $$v
                                          },
                                          expression: "selectedTotal",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticStyle: {
                                              height: "55px",
                                              display: "inline-flex",
                                              "justify-content": "center",
                                              "flex-direction": "column",
                                            },
                                            attrs: { border: "", label: "min" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  width: "120px",
                                                  "white-space": "normal",
                                                  "text-algin": "left",
                                                  "font-size": "12px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-weight": "bolder",
                                                      "font-size": "16px",
                                                      "padding-bottom": "6px",
                                                      color: "black",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "￥" +
                                                        _vm._s(
                                                          _vm.totalPreBillMoney
                                                            .minMoney
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#909399",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                缴至" +
                                                        _vm._s(
                                                          _vm.totalPreBillMoney
                                                            .minEndDay
                                                        ) +
                                                        "            \n              "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("el-col", [
                _c("div", { staticClass: "edit-bill-dialog-content" }, [
                  _c("div", { staticClass: "edit-bill-tips" }, [
                    _vm._v("优惠方式:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-bill-dialog-input" },
                    [
                      _c("v-select", {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          options: _vm.discountTypeOps,
                          width: _vm.width1,
                        },
                        model: {
                          value: _vm.discountType,
                          callback: function ($$v) {
                            _vm.discountType = $$v
                          },
                          expression: "discountType",
                        },
                      }),
                      _vm.discountType == 3
                        ? _c(
                            "div",
                            [
                              _c("v-input-number", {
                                attrs: {
                                  label: "",
                                  max: 9.9,
                                  min: 0.1,
                                  placeholder: "例如：9.5",
                                  precision: 1,
                                },
                                model: {
                                  value: _vm.modifyDiscount1,
                                  callback: function ($$v) {
                                    _vm.modifyDiscount1 = $$v
                                  },
                                  expression: "modifyDiscount1",
                                },
                              }),
                              _vm._v("\n            折\n          "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.discountType == 2
                        ? _c(
                            "div",
                            [
                              _c("v-input-number", {
                                attrs: {
                                  label: "总优惠",
                                  max: _vm.maxModifyInput1,
                                  min: -99999999.99,
                                  placeholder: "请输入金额",
                                  precision: 2,
                                },
                                model: {
                                  value: _vm.modifyDiscountMoney1,
                                  callback: function ($$v) {
                                    _vm.modifyDiscountMoney1 = $$v
                                  },
                                  expression: "modifyDiscountMoney1",
                                },
                              }),
                              _vm._v("\n            元\n            "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "优惠金额不允许大于选中账单金额减去历史优惠金额",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "tips-icon el-icon-question",
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.billType == 0
                ? [
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("本次优惠金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", { staticClass: "edit-bill-text" }, [
                            _vm._v(_vm._s(_vm.todayDiscountMoney1)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("应收总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [_vm._v(_vm._s(_vm.selectSubTotal1))]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("已收总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [_vm._v(_vm._s(_vm.selectPaidMoney))]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("未收总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [_vm._v(_vm._s(_vm.selectTotal1))]),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm.billType == 1
                ? [
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("本次优惠金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", { staticClass: "edit-bill-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.totalPreBillMoney[
                                  _vm.selectedTotal + "Discount"
                                ] &&
                                  _vm.totalPreBillMoney[
                                    _vm.selectedTotal + "Discount"
                                  ]
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("应收总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPreBillMoney[
                                  _vm.selectedTotal + "Money"
                                ] &&
                                  _vm.totalPreBillMoney[
                                    _vm.selectedTotal + "Money"
                                  ].toFixed(2)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("已收总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [_vm._v(_vm._s(_vm.selectPaidMoney))]),
                        ]),
                      ]),
                    ]),
                    _c("col2-item", [
                      _c("div", { staticClass: "edit-bill-dialog-content" }, [
                        _c("div", { staticClass: "edit-bill-tips" }, [
                          _vm._v("未收总金额（元）:"),
                        ]),
                        _c("div", { staticClass: "edit-bill-dialog-input" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPreBillMoney[
                                  _vm.selectedTotal + "Money"
                                ] &&
                                  _vm.totalPreBillMoney[
                                    _vm.selectedTotal + "Money"
                                  ].toFixed(2)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _c("col2-item", [
                _c("div", { staticClass: "edit-bill-dialog-content" }, [
                  _c("div", { staticClass: "edit-bill-tips" }, [
                    _vm._v("缴费人:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-bill-dialog-input" },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "请输入手机号搜索",
                              width: _vm.width,
                            },
                            on: { onChange: _vm.userChange },
                            model: {
                              value: _vm.noteForm.payUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.noteForm, "payUserId", $$v)
                              },
                              expression: "noteForm.payUserId",
                            },
                          },
                          "v-select2",
                          _vm.userParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("col2-item", [
                _c("div", { staticClass: "edit-bill-dialog-content" }, [
                  _c("div", { staticClass: "edit-bill-tips" }, [
                    _vm._v("支付方式:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-bill-dialog-input" },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.payTypeOps, width: _vm.width },
                        model: {
                          value: _vm.noteForm.moneyType,
                          callback: function ($$v) {
                            _vm.$set(_vm.noteForm, "moneyType", $$v)
                          },
                          expression: "noteForm.moneyType",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("col2-item", [
                _c("div", { staticClass: "edit-bill-dialog-content" }, [
                  _c("div", { staticClass: "edit-bill-tips" }, [
                    _c("span", { staticClass: "require" }, [_vm._v("*")]),
                    _vm._v("缴费人手机号:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-bill-dialog-input" },
                    [
                      _c("v-input", {
                        attrs: {
                          width: _vm.width,
                          placeholder: "未登记业主，请输入手机号",
                          disabled: _vm.selectFlag,
                        },
                        model: {
                          value: _vm.noteForm.payUserPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.noteForm, "payUserPhone", $$v)
                          },
                          expression: "noteForm.payUserPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("col2-item", [
                _c("div", { staticClass: "edit-bill-dialog-content" }, [
                  _c("div", { staticClass: "edit-bill-tips" }, [
                    _vm._v("票据:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-bill-dialog-input" },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.noteTypeOps, width: _vm.width },
                        model: {
                          value: _vm.noteForm.invoiceType,
                          callback: function ($$v) {
                            _vm.$set(_vm.noteForm, "invoiceType", $$v)
                          },
                          expression: "noteForm.invoiceType",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("col2-item", [
                _c("div", { staticClass: "edit-bill-dialog-content" }, [
                  _c("div", { staticClass: "edit-bill-tips" }, [
                    _c("span", { staticClass: "require" }, [_vm._v("*")]),
                    _vm._v("缴费人姓名:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-bill-dialog-input" },
                    [
                      _c("v-input", {
                        attrs: {
                          width: _vm.width,
                          placeholder: "未登记业主，请输入姓名",
                          disabled: _vm.selectFlag,
                        },
                        model: {
                          value: _vm.noteForm.payUserName,
                          callback: function ($$v) {
                            _vm.$set(_vm.noteForm, "payUserName", $$v)
                          },
                          expression: "noteForm.payUserName",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("col2-item", [
                _c("div", { staticClass: "edit-bill-dialog-content" }, [
                  _c("div", { staticClass: "edit-bill-tips" }, [
                    _vm._v("发票号:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-bill-dialog-input" },
                    [
                      _c("v-input", {
                        attrs: {
                          width: _vm.width,
                          placeholder: "请输入纸质发票号",
                        },
                        model: {
                          value: _vm.noteForm.invoiceNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.noteForm, "invoiceNumber", $$v)
                          },
                          expression: "noteForm.invoiceNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-container" },
    [
      _vm.viewPage === 1
        ? _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              { staticClass: "bussiness-container" },
              _vm._l(_vm.bussinessItems, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "bussiness-item",
                    on: {
                      click: function ($event) {
                        return _vm.selectScene(item)
                      },
                    },
                  },
                  [
                    _c("img", { attrs: { src: item.icon } }),
                    _c("span", [_vm._v(_vm._s(item.customName))]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.viewPage === 2
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "orderForm",
              attrs: { model: _vm.form, "label-width": "80px" },
            },
            [
              !_vm.showNewOrder
                ? _c(
                    "div",
                    { staticClass: "goback-wrapper" },
                    [
                      _c(
                        "v-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.viewPage = 1
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showNewOrder
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "选择分类",
                          prop: "categoryName",
                          rules: [
                            {
                              required: true,
                              message: "选择分类",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("el-cascader", {
                          attrs: {
                            props: { expandTrigger: "hover" },
                            options: _vm.orderTypeList,
                          },
                          on: { change: _vm.changeCascader },
                          model: {
                            value: _vm.orderTypeValue,
                            callback: function ($$v) {
                              _vm.orderTypeValue = $$v
                            },
                            expression: "orderTypeValue",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "房号信息",
                          rules: [{ required: true }],
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.taskInfoAddress) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "用户信息",
                          prop: "userId",
                          rules: [
                            {
                              required: true,
                              message: "请选择业主",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { disabled: !_vm.houseId },
                            model: {
                              value: _vm.form.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userId", $$v)
                              },
                              expression: "form.userId",
                            },
                          },
                          _vm._l(_vm.userInfoOpts, function (item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: {
                                label: item.userName,
                                value: item.userId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _vm.form.subCode !== "131"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "选择分类",
                              prop: "orderNumber",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择报事类型",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                options: _vm.subjectOps,
                                width: _vm.halfWidth,
                              },
                              model: {
                                value: _vm.form.orderNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orderNumber", $$v)
                                },
                                expression: "form.orderNumber",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.subCode === "131"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "选择分类",
                              prop: "categoryMappingId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择报事类型",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-select", {
                              staticStyle: { "padding-right": "12px" },
                              attrs: {
                                options: _vm.reportTypeOps,
                                width: _vm.halfWidth,
                              },
                              on: { change: _vm.onReportTypeChange },
                              model: {
                                value: _vm.form.reportType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "reportType", $$v)
                                },
                                expression: "form.reportType",
                              },
                            }),
                            _c("v-select", {
                              attrs: {
                                options: _vm.categoryOps,
                                width: _vm.halfWidth,
                              },
                              model: {
                                value: _vm.form.categoryMappingId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "categoryMappingId", $$v)
                                },
                                expression: "form.categoryMappingId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "用户信息",
                          prop: "userId",
                          rules: [
                            {
                              required: true,
                              message: "请选择业主",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              ref: "userSelect",
                              attrs: {
                                handleData: _vm.handleUserData,
                                placeholder: "查询用户",
                                subjoin: { communityId: _vm.communityId },
                                width: _vm.width,
                              },
                              on: { onChange: _vm.onUserChange },
                              model: {
                                value: _vm.form.userId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "userId", $$v)
                                },
                                expression: "form.userId",
                              },
                            },
                            "v-select2",
                            _vm.userParams,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "房号信息",
                          prop: "houseId",
                          rules: [
                            {
                              required: true,
                              message: "请选择房号",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: { options: _vm.houseOps, width: _vm.width },
                          on: { change: _vm.onHouseChange },
                          model: {
                            value: _vm.form.houseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "houseId", $$v)
                            },
                            expression: "form.houseId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "工单内容",
                    prop: "orderRemark",
                    rules: [
                      {
                        required: true,
                        message: "请填写工单内容",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      maxlength: 500,
                      placeholder: "请输入内容",
                      rows: 3,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.orderRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderRemark", $$v)
                      },
                      expression: "form.orderRemark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "工单照片" } },
                [
                  _c("v-uploader", {
                    attrs: {
                      action: _vm.uploadURL,
                      imgUrls: _vm.form.imageUrls,
                      limit: 4,
                    },
                    on: {
                      "update:imgUrls": function ($event) {
                        return _vm.$set(_vm.form, "imageUrls", $event)
                      },
                      "update:img-urls": function ($event) {
                        return _vm.$set(_vm.form, "imageUrls", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              !_vm.isOpenTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "服务时间", rules: { required: true } } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline-form-item",
                          attrs: {
                            prop: "orderDate",
                            rules: [
                              {
                                required: true,
                                message: "请选择服务日期",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.orderDateOps },
                            on: { change: _vm.onDateChange },
                            model: {
                              value: _vm.form.orderDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderDate", $$v)
                              },
                              expression: "form.orderDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline-form-item",
                          attrs: {
                            prop: "orderTime",
                            rules: [
                              {
                                required: true,
                                message: "请选择服务时间",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.orderTimeOps },
                            model: {
                              value: _vm.form.orderTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderTime", $$v)
                              },
                              expression: "form.orderTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// 查询指定房号及业主信息
// const getRoomAndOwnerInfoURL = `http://172.16.11.195:7020/manage/workbench/getRoomAndOwnerInfo`
const getRoomAndOwnerInfoURL = `${API_CONFIG.butlerBaseURL}workbench/getRoomAndOwnerInfo`

// 获取指定房号下的所有成员详细信息

// const getUserDetailByRoomIdURL = `http://172.16.11.195:7020/manage/workbench/getUserDetailByRoomId`
const getUserDetailByRoomIdURL = `${API_CONFIG.butlerBaseURL}workbench/getUserDetailByRoomId`

// 搬离组织
const delStructUserInfoUrl = `${API_CONFIG.butlerBaseURL}user/delStructUserInfo`

// 获取用户标签
const multiSelectUrl = `${API_CONFIG.controlBaseURL}label/getLabelList?status=1&labelType=4`

// 修改人员信息（新修改信息接口）
// https://yshk8s.lcfw.co/manage/user/register/updateRegisterUserBase
const updateSubmitUrl = `${API_CONFIG.butlerBaseURL}user/register/updateRegisterUserBase`


// 新增成员
const createSubmitUrl = `${API_CONFIG.baseURL}serverOwnerAction!savebase.action`
// 成员关联房产
const createHouseInfoUrl = `${API_CONFIG.butlerBaseURL}user/information/asset/add`

// 修改关联房产
// const updateHouseInfoUrl = `http://172.16.11.195:7020/manage/user/information/asset/update`
const updateHouseInfoUrl = `${API_CONFIG.butlerBaseURL}user/information/asset/update`


// 查询单个房号下所有的未缴帐单 分页查询


// const selectUnpaidChargeBillPageByHouseIdURL = `http://172.16.11.195:7020/manage/workbench/chargeBill/selectUnpaidChargeBillPageByHouseId`
const selectUnpaidChargeBillPageByHouseIdURL = `${API_CONFIG.butlerBaseURL}workbench/chargeBill/selectUnpaidChargeBillPageByHouseId`

// 查询指定成员下所有的未缴帐单总金额
// const selectUnpaidTotalMoneyByUserIdsURL = `http://172.16.11.195:7020/manage/workbench/chargeBill/selectUnpaidTotalMoneyByUserIds`
const selectUnpaidTotalMoneyByUserIdsURL = `${API_CONFIG.butlerBaseURL}workbench/chargeBill/selectUnpaidTotalMoneyByUserIds`


// 获取用户列表
const getOwnerListURL = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`

//缴费订单下单
const addOrderURL = `${API_CONFIG.butlerBaseURL}addOrder`

// 管理后台支付订单
const batchPayOrderURL = `${API_CONFIG.butlerBaseURL}batchPayOrder`

// 查询单个房号下所有的未缴帐单 不分页
// const selectUnpaidChargeBillListByHouseIdURL = `http://172.16.11.195:7020/manage/workbench/chargeBill/selectUnpaidChargeBillListByHouseId`
const selectUnpaidChargeBillListByHouseIdURL = `${API_CONFIG.butlerBaseURL}workbench/chargeBill/selectUnpaidChargeBillListByHouseId`

// 查询指定成员同项目的所有房号 包含车位、库房
// const selectRoomIdsByUserIdsURL = `http://172.16.11.195:7020/manage/workbench/chargeBill/selectRoomIdsByUserIds`
const selectRoomIdsByUserIdsURL = `${API_CONFIG.butlerBaseURL}workbench/chargeBill/selectRoomIdsByUserIds`

//缴费订单下单
const checkPartBillURL = `${API_CONFIG.butlerBaseURL}checkPartBillOrder`

// 获取未完成工单
const getListURL = `${API_CONFIG.taskDispatchURL}dispatch/task/query/room/list` 


export {
    getRoomAndOwnerInfoURL,
    getUserDetailByRoomIdURL,
    delStructUserInfoUrl,
    multiSelectUrl,
    updateSubmitUrl,
    createSubmitUrl,
    createHouseInfoUrl,
    updateHouseInfoUrl,
    selectUnpaidChargeBillPageByHouseIdURL,
    selectUnpaidTotalMoneyByUserIdsURL,
    getOwnerListURL,
    addOrderURL,
    batchPayOrderURL,
    selectUnpaidChargeBillListByHouseIdURL,
    selectRoomIdsByUserIdsURL,
    checkPartBillURL,
    getListURL
}
<template>
  <div class="order-container">
    <div class="switch" v-if="viewPage === 1">
      <div class="bussiness-container">
        <div class="bussiness-item" v-for="(item,index) in bussinessItems" :key="index" @click="selectScene(item)">
           <img :src="item.icon" />
           <span>{{ item.customName }}</span>
        </div>
      </div>
    </div>
    <el-form class="orderForm" ref="form" :model="form" label-width="80px" v-if="viewPage === 2">
      <div class="goback-wrapper" v-if="!showNewOrder">
        <v-button @click="viewPage=1">返回</v-button>
      </div>
<!--      新工单-->
      <template v-if="showNewOrder">
        <el-form-item label="选择分类" prop="categoryName" :rules="[{ required: true, message: '选择分类', trigger: 'blur' }]">
          <el-cascader
            v-model="orderTypeValue"
            :props="{ expandTrigger: 'hover' }"
            :options="orderTypeList"
            @change="changeCascader"
          >
          </el-cascader>
        </el-form-item>
<!--        先选房号再选用户信息-->
        <!-- <el-form-item label="房号信息" prop="houseId" :rules="[{ required: true, message: '请选择房号', trigger: 'blur' }]" >
          <v-select2 v-model="houseId" placeholder="查询房号" v-bind="roomIdConfig" :subjoin="communityIdParams" @onChange="getHouseType" :width="width"></v-select2>
        </el-form-item> -->
        <el-form-item label="房号信息" :rules="[{ required: true}]">
          {{taskInfoAddress}}
        </el-form-item>
        <el-form-item label="用户信息" prop="userId" :rules="[{ required: true, message: '请选择业主', trigger: 'blur' }]">
          <el-select v-model="form.userId" :disabled="!houseId">
            <el-option
              v-for="item in userInfoOpts"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId">
            </el-option>
          </el-select>
          <!-- <add-user :communityId="communityId" @getNewUser="onAddUserSuccess" /> -->
        </el-form-item>

      </template>
<!--      老工单-->
      <template v-else>
        <el-form-item  v-if="form.subCode !== '131'" label="选择分类" prop="orderNumber" :rules="[{ required: true, message: '请选择报事类型', trigger: 'blur' }]">
          <v-select v-model="form.orderNumber" :options="subjectOps" :width="halfWidth"></v-select>
        </el-form-item>
        <el-form-item v-if="form.subCode === '131'" label="选择分类" prop="categoryMappingId" :rules="[{ required: true, message: '请选择报事类型', trigger: 'blur' }]">
          <v-select v-model="form.reportType" :options="reportTypeOps" :width="halfWidth" style="padding-right:12px;" @change="onReportTypeChange"></v-select>
          <v-select v-model="form.categoryMappingId" :options="categoryOps" :width="halfWidth"></v-select>
        </el-form-item>
        <el-form-item label="用户信息" prop="userId" :rules="[{ required: true, message: '请选择业主', trigger: 'blur' }]">
          <v-select2
            ref="userSelect"
            :handleData="handleUserData"
            v-model="form.userId"
            placeholder="查询用户"
            v-bind="userParams"
            :subjoin="{ communityId: communityId } "
            :width="width"
            @onChange="onUserChange"></v-select2>
          <!-- <add-user :communityId="communityId" @getNewUser="onAddUserSuccess" /> -->
        </el-form-item>
        <el-form-item label="房号信息" prop="houseId" :rules="[{ required: true, message: '请选择房号', trigger: 'change' }]">
          <v-select v-model="form.houseId" :options="houseOps" :width="width" @change="onHouseChange"></v-select>
        </el-form-item>
      </template>


      <el-form-item label="工单内容" prop="orderRemark" :rules="[{ required: true, message: '请填写工单内容', trigger: 'change' }]">
         <v-textarea :maxlength="500" v-model="form.orderRemark" placeholder="请输入内容" :rows="3" :width="width"></v-textarea>
      </el-form-item>
      <el-form-item label="工单照片">
        <v-uploader :action="uploadURL" :imgUrls.sync="form.imageUrls" :limit="4">
          <!-- <template #tip>
            <span>注：建议宽1080px高810px，图片小于512KB</span>
          </template> -->
        </v-uploader>
      </el-form-item>
      <el-form-item label="服务时间" v-if="!isOpenTime" :rules="{ required: true }">
        <el-form-item class="inline-form-item" prop="orderDate" :rules="[{ required: true, message: '请选择服务日期', trigger: 'change' }]">
          <v-select v-model="form.orderDate" :options="orderDateOps" @change="onDateChange"></v-select>
        </el-form-item>
        <el-form-item class="inline-form-item" prop="orderTime" :rules="[{ required: true, message: '请选择服务时间', trigger: 'change' }]">
          <v-select v-model="form.orderTime" :options="orderTimeOps"></v-select>
        </el-form-item>
      </el-form-item>
      <!-- <el-form-item>
        <v-button @click="save">保存</v-button>
      </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
import {
  uploadURL,
  userParams,
  queryOrderTypeURL,
  querySubTypeURL,
  queryMaintainURL,
  queryHouseURL,
  queryServiceTimeURL,
  getServiceNameURL,
  saveOrderURL,
  checkNewWorkOrder,
  getTreeByRegionIdURL,
  addNewReport,
  select2RoomIdUrl,
  getOwnerByRoomId
} from '../components/quickActions/api'
// import AddUser from './addUser'
import { vUploader } from 'components/control'
import { handleUserData } from '../components/quickActions/common'
// import * as allOwnerMgrFormUrl from "views/user/ownerMgr/ownerMgrForm/api";
const ICON = {
  record: require('../components/quickActions/images/record.png'),
  express: require('../components/quickActions/images/express.png')
}

export default {
  components: {
    // AddUser,
    vUploader
  },
  props: {
    width: Number,
    communityId: {
      type: String,
      default: ''
    },
    houseId: {
      type: String,
      default: ''
    },
    taskInfoAddress: {
      type: String,
      default: ''
    }
  },
  computed: {
    halfWidth () {
      return (this.width - 12) / 2
    },
    theme () {
      return this.$store.state.theme
    },
    communityIdParams () {
      return {
        communityId: this.communityId,
      }
    },
  },
  data () {
    return {
      uploadURL,
      userParams,
      handleUserData,
      secondOps: [],
      form: {
        userId: '',
        imageUrls: [],
        reportType: undefined,
        houseId: undefined,
        userAddress: '',
        subCode: undefined,
        subjectId: undefined,
        userName: '',
        userPhone: '',
        orderRemark: '',
        orderDate: undefined,
        orderTime: undefined,
        orderNumber: undefined,
        categoryMappingId: undefined,
        categoryId: null, // 新工单id
        categoryName: '', // 新工单name
        taskInfoAddress: '',// 报事人报事地址
      },
      bussinessItems: [],
      viewPage: 1,
      reportTypeOps: [],
      subjectOps: [],
      houseOps: [],
      orderDateOps: [],
      orderTimeOps: [],
      timeZeroOps: [],
      timeNormalOps: [],
      isOpenTime: 1,
      categoryOps: [],
      regionId: null,
      showNewOrder: false, // true 新工单
      orderTypeList: [],// 工单分类列表
      orderTypeValue:[],
      roomIdConfig: {
        searchUrl: select2RoomIdUrl,
        request: {
          text: 'address',
          value: 'id'
        },
        response: {

        }
      },
      userInfoOpts: []
    }
  },
  async created () {
    // 判断是否为新工单 true该项目有权限
    await this.checkNewWorkOrder()
  },
  mounted () {
    this.queryScencList()
    this.queryServiceName()
    this.getHouseType()
  },
  methods: {
    getSujectOps () {
    },
    update (data) {
      this.form.name = data.name
    },
    submitBefore (data) {
      this.ruleId && (data.id = Number(this.ruleId))
      data.communityIds = this.form.communityIds ? this.form.communityIds : this.communityIdsList.map(item => item.id)
      return true
    },
    selectScene (item) {
      this.viewPage = 2
      this.form.subCode = item.subCode
      this.form.subjectId = item.subId
      this.subjectOps = []
      this.form.orderNumber = undefined

      this.querySubjectList()
      this.queryServiceTime(item.id)
      this.autoSelect()
    },
    onReportTypeChange (value, item) {
      this.categoryOps = item.service
      this.form.categoryMappingId = this.categoryOps && this.categoryOps.length > 0 ? this.categoryOps[0].value : undefined
    },
    // 查询可用场景
    async queryScencList () {
      // 新工单不需要这个
      if(this.showNewOrder) return;

      if (!this.communityId || this.communityId.length === 0) {
        return
      }
      let { data } = await this.$axios.get(queryOrderTypeURL, {
        params: {
          communityId: this.communityId
        }
      })
      this.bussinessItems = data.map(item => ({
        ...item,
        icon: item.subImg
      })) || []
      let maintain = this.bussinessItems.find(item => item.subCode === '131')
      if (maintain) {
        this.queryMaintainType(maintain.id)
      }
    },
    // 查询维修分类
    async queryMaintainType (id) {
      let url = `${queryMaintainURL}${id}`
      let { data } = await this.$axios.get(url)
      let arr = []
      arr.push({
        service: data.commonService.map(item => ({
          ...item,
          text: item.categoryName,
          value: item.id
        })),
        desc: data.commonServiceDesc,
        name: data.commonServiceName
      })
      arr.push({
        service: data.houseService.map(item => ({
          ...item,
          text: item.categoryName,
          value: item.id
        })),
        desc: data.houseServiceDesc,
        name: data.houseServiceName
      })
      this.reportTypeOps = arr.map(item => ({
        ...item,
        text: item.name,
        value: item.name
      }))
      // 自动选择第一个
      this.autoSelect()
    },
    // 查询其他场景的分类
    async querySubjectList () {
      if (this.form.subCode !== '131' && this.form.subCode) {
        let { data } = await this.$axios.get(querySubTypeURL, {
          params: {
            code: this.form.subCode,
            communityId: this.communityId
          }
        })
        this.subjectOps = data.map(item => ({
          ...item,
          text: item.name
        }))
      }
    },
    onUserChange (item) {
      if (item) {
        this.form.orgId = item.orgId
        this.form.userName = item.userName
        this.form.userPhone = item.mobileNum
        this.queryHouseList()
      } else {
        this.houseOps = []
      }
      this.form.houseId = undefined
    },
    async queryHouseList () {
      let { data } = await this.$axios.get(queryHouseURL, {
        params: {
          communityId: this.communityId,
          userId: this.form.userId,
          orgId: this.form.orgId
        }
      })
      this.houseOps = data.map(item => {
        let nameArray = []
        let keys = ['communityName', 'address', 'buildNum', 'unitNum', 'roomNum']
        keys.forEach(key => {
          if (item[key]) {
            nameArray.push(item[key])
          }
        })
        let name = nameArray.join('-')
        return {
          ...item,
          text: name,
          value: item.roomId
        }
      })
      if (this.houseOps.length === 1) {
        this.form.houseId = this.houseOps[0].value
      }
    },
    onHouseChange (value, item) {
      if (item) {
        this.form.userAddress = item.text
        this.form.taskInfoAddress = item.text
      }
    },
    // 查询服务时间
    async queryServiceTime (subjectId) {
      let { status, data } = await this.$axios.get(queryServiceTimeURL, {
        params: {
          subjectId
        }
      })
      if (Number(status) === 100) {
        if (data) {
          this.isOpenTime = data.isOpenTime
          if (this.isOpenTime === 0) {
            this.orderDateOps = data.days.map(text => ({ text, value: text }))
            this.form.orderDate = this.orderDateOps.length ? this.orderDateOps[0].value : undefined
            this.timeNormalOps = data.timeNormal.map(text => ({ text, value: text }))
            this.timeZeroOps = data.timeZero.map(text => ({ text, value: text }))
            this.form.orderDate && this.onDateChange(this.form.orderDate)
          }
        }
      }
    },
    onDateChange (date) {
      let index = this.orderDateOps.findIndex(item => item.value === date)
      if (index) {
        this.orderTimeOps = this.timeNormalOps
      } else {
        this.orderTimeOps = this.timeZeroOps
      }
      this.form.orderTime = this.orderTimeOps.length ? this.orderTimeOps[0].value : undefined
    },
    async queryServiceName () {
      if (!this.communityId || this.communityId.length === 0) {
        return
      }
      let { data } = await this.$axios.get(getServiceNameURL, {
        params: {
          communityId: this.communityId
        }
      })
    },
    async save () {
      try {
        await this.$refs.form.validate()
      } catch (error) {
        return false
      }
      // 新工单
      if(this.showNewOrder) {
        let params = {
          communityId: this.communityId,
          description: this.form.orderRemark,
          reporterId: this.form.userId,
          roomId: this.form.houseId,
          source: 4
        }
        for (const key in this.form) {
          if (Object.hasOwnProperty.call(this.form, key)) {
            const element = this.form[key];
            if(element === 0 || element) {
              params[key] = element
            }
          }
        }
        params.images = params.imageUrls.join('|')
        Reflect.deleteProperty(params,'imageUrls')
        // 删除
        // 老工单的描述
        Reflect.deleteProperty(params,'orderRemark')
        // 地址
        Reflect.deleteProperty(params,'userAddress')
        // userId
        Reflect.deleteProperty(params,'userId')
        // houseId
        Reflect.deleteProperty(params,'houseId')
        console.log(params,'params')
        let { status } = await this.$axios.post(addNewReport, params)
        if (status === 100) {
          this.$emit('closeOrderDialog')
          this.$message.success('下单成功')
          this.clearFormData()
        }
      }else {// 老工单
        let params = {
          ...this.form,
          communityId: this.communityId,
          moneyType: 0
        }
        delete params.reportType
        if (this.form.subCode === '131') {
          delete params.orderNumber
        }
        let { status } = await this.$axios.post(`${saveOrderURL}?${this.$qs.stringify(params)}`)
        if (status === 100) {
          this.$emit("closeOrderDialog")
          this.$message.success('下单成功')
          this.clearFormData()
        }
      }
    },
    clearFormData () {
      this.form = {
        userId: '',
        imageUrls: [],
        reportType: undefined,
        houseId: undefined,
        userAddress: '',
        subCode: undefined,
        subjectId: undefined,
        userName: '',
        userPhone: '',
        orderRemark: '',
        orderDate: undefined,
        orderTime: undefined,
        orderNumber: undefined,
        categoryMappingId: undefined,
        categoryId: null, // 新工单id
        categoryName: '', // 新工单name
        taskInfoAddress: '',// 报事人报事地址
      }
      this.orderTypeValue = []
      if(!this.showNewOrder) {
        this.viewPage = 1
        this.$refs.userSelect && this.$refs.userSelect.clearValue()
      }
      this.$refs.form && this.$refs.form.resetFields()
    },
    autoSelect () {
      if (this.reportTypeOps.length) {
        this.form.reportType = this.reportTypeOps[0].value
        this.onReportTypeChange(this.reportTypeOps[0].value, this.reportTypeOps[0])
        if (this.categoryOps.length) {
          this.form.categoryMappingId = this.categoryOps[0].value
        }
      }
    },
    onAddUserSuccess (data) {
      this.form.userId = data
    },

    resetData () {
      this.form.userId = ''
      this.form.roomId = undefined
      this.bussinessItems = []
      this.queryScencList()
      this.queryServiceName()
    },
    // 判断新老工单
    async checkNewWorkOrder() {
      // this.getTreeByRegionIdURL('eb1e1ad8-85af-42d0-ba3c-21229be19009')
      // this.viewPage = 2
      // this.showNewOrder = true
      // return;
      let { data } = await this.$axios.get(checkNewWorkOrder, {
        params: {
          communityId: this.communityId
        }
      })
      console.log(data)
      if(data && data.flag) {
        // 公司id用于搜索新工单的类型
        this.regionId = data.regionId
        this.showNewOrder = data.flag
        this.viewPage = 2
        this.getTreeByRegionIdURL(this.regionId)
      }
    },
    // 获取新工单分类
    getTreeByRegionIdURL(regionId) {
      this.$axios.get(getTreeByRegionIdURL, {
        params: {
          regionId
        }
      }).then(res => {
        if (res.status === 100) {
          this.orderTypeList = this.handleOrderOption(res.data)
        }
      })
    },
    // 处理新工单分类选项
    handleOrderOption(typeList) {
      if(typeList.length === 0) return [];
      return typeList.map(item => {
        const children = this.handleOrderOption(item.childrenList)
        const params = {
          value: item.name,
          label: item.name,
          id: item.id
        }
        if(children.length > 0) {
          params.children = children
        }
        return params
      })
    },
    // 工单分类级联
    changeCascader(e) {

      this.form.categoryId = this.handleOrderId(this.orderTypeList, e)
      console.log('this.categoryId', this.form.categoryId);
      this.form.categoryName = e.join('-')
      // this.form.categoryName = e.slice(-1)[0]
      console.log('this.categoryName', this.form.categoryName);

      // console.log('lastId', lastId);
    },
    // 根据级联数组拿到最后一级id
    handleOrderId(list, nameList) {
      nameList = JSON.parse(JSON.stringify(nameList))
      const currentName = nameList.shift()
      const findData = list.find(item => {
        if(item.label == currentName) {
          return true
        }
      })
      if(findData.children) {
        return this.handleOrderId(findData.children, nameList)
      }else {
        return findData.id
      }
    },
    // 根据communityId 获取房号
    getHouseType (item) {
      this.form.userId = ''
      this.form.houseId = this.houseId
      this.form.taskInfoAddress = this.taskInfoAddress
      if(!this.form.houseId) {
        return
      }
      this.$axios.get(getOwnerByRoomId, {
        params: {
          roomId: this.form.houseId
        }
      }).then(res => {
        if (res.status === 100) {
          this.userInfoOpts = res.data
        }
      })
    }
  },
  watch: {
    width (newVal) {
      console.log('order', this.width)
    },
    async communityId () {
      // 判断是否为新工单 true该项目有权限
      await this.checkNewWorkOrder()
      this.clearFormData()
      this.resetData()
    }
  }
}
</script>
<style lang="scss" scoped>
.order-container {
  .orderForm {
    flex-direction: column;
    width: 100%;
    > .el-form-item {
      text-align: left;
      margin-bottom: 16px;
    }
  }
}
.inline-form-item {
  display: inline-block;
  margin-right: 15px;
}
.goback-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>

<template>
  <div>
    <!-- List -->
    <list-other ref="list"
        exportPermission="export"
        :searchUrl="searchUrl"
        :searchParams.sync="searchParams"
        :headers="headers"
        :hasOperateColumn="false"
        :isMultiSelect="false"
        :immediate="false"
        @searchSuccess="searchSuccess">
    </list-other>
  </div>
</template>

<script>
import { List } from 'components/bussiness'
import {selectUnpaidChargeBillPageByHouseIdURL,selectUnpaidChargeBillListByHouseIdURL} from './api'
import Big from 'big.js';
export default {
    name:'billTable',
    components:{
        List
    },
    props:{
    },
    data(){
        return{
            hasOperateColumn:false,
            headers:[
                {
                    prop:'subjectName',
                    label:'收费科目',
                },
                {
                    prop:'billingTime',
                    label:'计费周期',
                    formatter (row) {
                        let billingStartTime = row.billingStartTime.split(' ')[0]
                        let billingEndTime = row.billingEndTime.split(' ')[0]
                        return `${billingStartTime}~${billingEndTime}`
                    }
                },
                {
                    prop:'totalMoneySum',
                    label:'账单金额',
                },
                {
                    prop:'discountMoneySum',
                    label:'优惠金额',
                },
                {
                    prop:'billMoneySum',
                    label:'应收金额',
                }
            ],
            searchUrl:selectUnpaidChargeBillPageByHouseIdURL,
            searchParams:{
                // userIds:[],
                houseId:undefined,
                pageSize:10
            },
            billMoneySum:new Big(0),
            billsLength:'',
            billsList:[]
        }
    },
    created(){

    },
    mounted(){
        // this.$nextTick(()=>{
        //     this.selectUnpaidTotalMoneyByUserIdsURL()
        // })
    },
    methods:{
        selectUnpaidChargeBillListByHouseId(payRoomId){
            this.$axios({
                method:"GET",
                url:selectUnpaidChargeBillListByHouseIdURL,
                params:{
                  houseId:payRoomId
                }
            }).then(res=>{
                if(res.status === 100){
                    let billMoneySum = res.data.reduce((a,b) =>{ return a + b.totalMoney},0)
                    this.billMoneySum = new Big(billMoneySum).toFixed(2).replace(/\d(?=(?:\d{3})+\b)/g,'$&,')
                    this.$nextTick(()=>{
                        if(this.billMoneySum && this.billsLength !== '') this.getAllPrice()
                    })
                }
            })
        },
        getAllPrice(){
            this.$emit('getAllPrice',{billMoneySum:this.billMoneySum,billsLength:this.billsLength})
        },
        searchSuccess(val){
            this.billsList.push(...val.data.resultList)
            this.billsLength = val.data.maxRow
        },
        async changeTableList(payRoomId){
            this.searchParams.houseId = payRoomId
            this.$refs.list.searchData()
            this.selectUnpaidChargeBillListByHouseId(payRoomId)
        }
    },
    
    watch:{
        billsLength(){
            this.getAllPrice()
        }
    }
}
</script>

<style>

</style>
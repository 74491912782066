<template>
  <div ref="payFeeDialog">
      <col2-detail>
        <col2-block>
      
        <template  v-if="billType == 0">
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">账单数量:</div>
          <div class="edit-bill-dialog-input">
            <div class="edit-bill-text">{{selectBillList.length}}</div>
          </div>
        </div>
        </col2-item>
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">账单总金额（元）:</div>
          <div class="edit-bill-dialog-input">
            <div>{{selectTotalMoney}}</div>
          </div>
        </div>
        </col2-item>
        
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">违约金总金额（元）:</div>
          <div class="edit-bill-dialog-input">
            <div>{{selectLateFee}}</div>
          </div>
        </div>
        </col2-item>
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">历史优惠总金额（元）:</div>
          <div class="edit-bill-dialog-input">
            <div>{{selectDiscountMoney}}</div>
          </div>
        </div>
        </col2-item>
        </template>
        <template v-else>
          <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">账单数量:</div>
            <div class="edit-bill-dialog-input">
              <div class="edit-bill-text">{{partBillTotalNumber}}</div>
            </div>
          </div>
          </col2-item>
          <col2-item>
            <div class="edit-bill-dialog-content">
              <div class="edit-bill-tips">账单总金额（元）:</div>
              <div class="edit-bill-dialog-input">
                <div>{{totalPreBillMoney[selectedTotal+'TotalMoney']&&totalPreBillMoney[selectedTotal+'TotalMoney'].toFixed(2)}}</div>
              </div>
            </div>
          </col2-item>
          <col2-item>
            <div class="edit-bill-dialog-content">
              <div class="edit-bill-tips">违约金总金额（元）:</div>
              <div class="edit-bill-dialog-input">
                <div>{{totalPreBillMoney[selectedTotal+'LateFee']&&totalPreBillMoney[selectedTotal+'LateFee'].toFixed(2)}}</div>
              </div>
            </div>
          </col2-item>
          <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">历史优惠总金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div>{{totalPreBillMoney[selectedTotal+'DiscountMoney']&&totalPreBillMoney[selectedTotal+'DiscountMoney'].toFixed(2)}}</div>
            </div>
          </div>
          </col2-item>
        </template>
        <el-col> <!-- 先隐藏掉 周一再上 -->
        <div class="edit-bill-dialog-content" style="justify-content:flex-start">
          <div class="edit-bill-tips">缴费形式:</div>
          <div div class="edit-bill-dialog-input">
             <v-select v-model="billType" :options="billTypeOpts" :width="width"  style="margin-right:10px" />
              <template v-if="billType == 1">
                <v-input-number
                  label=""
                  v-model="prebillMoney"
                    placeholder="请输入金额"
                    :precision="2">
                  </v-input-number>
              </template>
          </div>
        </div>
        </el-col>
         <el-col>
        <div class="edit-bill-dialog-content" style="justify-content:flex-start;padding-left:60px;padding-top:35px" v-if="billType == 1" >
          <div style="max-height:188px;overflow:auto">
            <div style="display:flex;flex-wrap:wrap;width:1000px;">
                <div v-for="(bill,index) in selectBillList" style="width:100px;height:90px;position:relative;border-top:2px solid #DCDFE6;border-bottom:2px solid #DCDFE6">
                    <div v-if="index<=preBillInfo.index" style="box-sizing: border-box;position:absolute;bottom:0;left:0;width:100px;height:90px;background:#E4EFFF;border-bottom:2px solid #2077FF;"></div>
                    <div style="box-sizing:border-box;position:absolute;bottom:0;left:0;height:90px;background:#E4EFFF;border-bottom:2px solid #2077FF;" :style="{width: halfBillInfo.percent + '%'}" v-else-if="index === preBillInfo.index + 1 ">
                      
                    </div>
                    <div v-else style="box-sizing:border-box;position:absolute;top:0;left:0;width:100%;height:90px;"></div>
                    <el-popover
                      trigger="hover"
                      title="账单明细"
                      :open-delay="300"
                    >
                      <div>
                        账单周期：{{bill.billingStartTime.split(" ")[0] }} ~ {{bill.billingEndTime.split(" ")[0]}} <br />
                        账单金额：￥{{bill.totalMoney}} <br />
                        <template v-if="bill.lateFreeVisible == 1">
                          违约金：￥{{bill.lateFee}} <br />
                        </template>
                        <template v-if="Number(bill.discountMoney)>0">
                          账单折扣：￥{{bill.discountMoney}}<br />
                        </template>
                        应收金额：￥{{bill.billMoney}}
                      </div>
                      <template #reference>
                        <div style="position: relative;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                  width: 100px;
                                  height: 90px;
                                  cursor:pointer;
                                  padding:5px;
                                  box-sizing:border-box;
                                  overflow:hidden;
                                  font-size:10px;"
                          >
                            <div style="transform: scale(0.85);color: #666;"> {{bill.billingStartTime.split(" ")[0] }}</div>
                            <div style="transform: scale(0.85);color: #666;">  ~ {{bill.billingEndTime.split(" ")[0]}}</div>
                            <div style="padding-top:10px;color：#333;font-size:14px;width: 100%;overflow: hidden;text-overflow: ellipsis;">  ￥{{bill.billMoney}}</div>
                        </div>
                      </template>
                    </el-popover>
                </div>
            </div>
          </div>
        </div>
        </el-col>
        <el-col>
        <div v-if="billType == 1&&prebillMoney>0" style="justify-content:flex-start;padding-left:60px;text-align:left;padding-bottom:20px">
          <div style="color:#909399;font-size: 12px;padding-top:24px">请选择一个时间段账单</div>
  
         <div class="edit-bill-dialog-content" v-if="billType == 1" >
          <template v-if="totalPreBillMoney.minMoney != totalPreBillMoney.maxMoney">
            <el-radio-group  v-model="selectedTotal"   v-if="Number(totalPreBillMoney.minMoney)!=0">
                <el-radio style="height:55px;display: inline-flex;justify-content: center;flex-direction: column;" border label="min">
                  <div style="text-align:center;width:120px;white-space:normal;text-algin:left;font-size:12px">
                    <div style="font-weight:bolder;font-size:16px;padding-bottom:6px;color:black">￥{{totalPreBillMoney.minMoney}}</div>   
                    <div style="color:#909399">
                      缴至{{totalPreBillMoney.minEndDay}}            
                    </div> 
                  </div>
                </el-radio>
                <el-radio border style="height:55px;display: inline-flex;justify-content: center;flex-direction: column;" label="max">
                   <div style="text-align:center;width:120px;white-space:normal;text-algin:left;font-size:12px">
                    <div style="font-weight:bolder;font-size:16px;padding-bottom:6px;color:black">￥{{totalPreBillMoney.maxMoney}}</div> 
                    <div style="color:#909399">
                      缴至{{totalPreBillMoney.maxEndDay}}             
                    </div>   
                  </div>
                </el-radio>
            </el-radio-group>
            <el-radio-group v-else  v-model="selectedTotal" readonly>
              <el-radio style="height:55px;display: inline-flex;justify-content: center;flex-direction: column;" border label="max">
                <div style="text-align:center;width:120px;white-space:normal;text-algin:left;font-size:12px">
                  <div style="font-weight:bolder;font-size:16px;padding-bottom:6px;color:black">￥{{totalPreBillMoney.maxMoney}}</div> 
                  <div style="color:#909399">
                    缴至{{totalPreBillMoney.maxEndDay}}            
                  </div>   
                </div>
              </el-radio>
            </el-radio-group>
          </template>
          <el-radio-group v-else-if="Number(totalPreBillMoney.minMoney) != 0"  v-model="selectedTotal" readonly>
            <el-radio style="height:55px;display: inline-flex;justify-content: center;flex-direction: column;" border label="min">
              <div style="text-align:center;width:120px;white-space:normal;text-algin:left;font-size:12px">
                <div style="font-weight:bolder;font-size:16px;padding-bottom:6px;color:black">￥{{totalPreBillMoney.minMoney}}</div> 
                <div style="color:#909399">
                  缴至{{totalPreBillMoney.minEndDay}}            
                </div>   
              </div>
            </el-radio>
          </el-radio-group>
          </div>
        </div>
        </el-col>
        <el-col>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">优惠方式:</div>
          <div class="edit-bill-dialog-input">
            <v-select v-model="discountType" :options="discountTypeOps" :width="width1" style="margin-right:10px" />
            <div v-if="discountType==3">
              <v-input-number
              label=""
              v-model="modifyDiscount1"
              :max="9.9"
              :min="0.1"
              placeholder="例如：9.5"
              :precision="1">
              </v-input-number>
              折
            </div>
            <div v-if="discountType==2">
              <v-input-number
              label="总优惠"
              v-model="modifyDiscountMoney1"
                :max="maxModifyInput1"
                :min="-99999999.99"
                placeholder="请输入金额"
                :precision="2">
              </v-input-number>
              元
              <el-tooltip content="优惠金额不允许大于选中账单金额减去历史优惠金额" placement="top">
                <i class="tips-icon el-icon-question"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
        </el-col>
        <template v-if="billType == 0">
        <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">本次优惠金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div class="edit-bill-text">{{todayDiscountMoney1}}</div>
            </div>
          </div>
          </col2-item>
          <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">应收总金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div>{{selectSubTotal1}}</div>
            </div>
          </div>
          </col2-item>
          <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">已收总金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div>{{selectPaidMoney}}</div>
            </div>
          </div>
          </col2-item>
          <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">未收总金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div>{{selectTotal1}}</div>
            </div>
          </div>
          </col2-item>
        </template>
        <template v-if="billType == 1">
        <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">本次优惠金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div class="edit-bill-text">{{totalPreBillMoney[selectedTotal + 'Discount']&&totalPreBillMoney[selectedTotal + 'Discount']}}</div>
            </div>
          </div>
          </col2-item>
           <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">应收总金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div>{{totalPreBillMoney[selectedTotal+'Money']&&totalPreBillMoney[selectedTotal+'Money'].toFixed(2)}}</div>
            </div>
          </div>
          </col2-item>
           <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">已收总金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div>{{selectPaidMoney}}</div>
            </div>
          </div>
          </col2-item>
           <col2-item>
          <div class="edit-bill-dialog-content">
            <div class="edit-bill-tips">未收总金额（元）:</div>
            <div class="edit-bill-dialog-input">
              <div>{{totalPreBillMoney[selectedTotal+'Money']&&totalPreBillMoney[selectedTotal+'Money'].toFixed(2)}}</div>
            </div>
          </div>
          </col2-item>
        </template>
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">缴费人:</div>
          <div class="edit-bill-dialog-input">
            <v-select2
                v-model="noteForm.payUserId"
                placeholder="请输入手机号搜索"
                v-bind="userParams"
                @onChange="userChange"
                :width="width"
            ></v-select2>
          </div>
        </div>
        </col2-item>
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">支付方式:</div>
          <div class="edit-bill-dialog-input">
            <v-select v-model="noteForm.moneyType" :options="payTypeOps" :width="width" />
          </div>
        </div>
        </col2-item>
        
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips"><span class="require">*</span>缴费人手机号:</div>
          <div class="edit-bill-dialog-input">
            <v-input v-model="noteForm.payUserPhone" :width="width" placeholder="未登记业主，请输入手机号" :disabled="selectFlag"></v-input>
          </div>
        </div>
        </col2-item>
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">票据:</div>
          <div class="edit-bill-dialog-input">
            <v-select v-model="noteForm.invoiceType" :options="noteTypeOps" :width="width" />
          </div>
        </div>
        </col2-item>

        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips"><span class="require">*</span>缴费人姓名:</div>
          <div class="edit-bill-dialog-input">
            <v-input v-model="noteForm.payUserName" :width="width" placeholder="未登记业主，请输入姓名" :disabled="selectFlag"></v-input>
          </div>
        </div>
        </col2-item>
     
        <col2-item>
        <div class="edit-bill-dialog-content">
          <div class="edit-bill-tips">发票号:</div>
          <div class="edit-bill-dialog-input">
            <v-input v-model="noteForm.invoiceNumber" :width="width" placeholder="请输入纸质发票号"></v-input>
          </div>
        </div>
        </col2-item>
        </col2-block>
      </col2-detail>
      <!-- <div class="footer">
        <v-button type="" text="取消" @click="concelPayFeeDialog(2)"></v-button>
        <v-button class="confirm-button"  @click="confirmPay" text="缴纳"></v-button>
      </div> -->
  </div>
</template>

<script>
import { getOwnerListURL, addOrderURL, batchPayOrderURL,selectUnpaidChargeBillListByHouseIdsURL,checkPartBillURL } from './api'

import { Col2Block, Col2Item, Col2Detail, TablePanel } from 'components/bussiness'
import { Popover } from 'element-ui'
import { parse } from 'querystring'
import Big from 'big.js'
import moment from 'moment'
export default {
    name: 'payFeeDialog',
    components: {
        Col2Block,
        Col2Item,
        Col2Detail,
        TablePanel,
        elPopover: Popover
    },
    props:{
        selectBillList:{
            type:Array,
            default(){
                return []
            }
        },
        houseId:{
            type:String,
            default(){
                return ''
            }
        },
        communityId:{
            type:String,
            default(){
                return ''
            }
        }
    },
    data () {
    let ORG_NAME = this.$store.state.baseConfig.docConfig.ORG_NAME || ''
    return {
        prebillMoney:0,
      selectedTotal:"min",
      billType:0,
      discountType: 3,
      modifyDiscount: undefined,
      modifyDiscount1: undefined,
      modifyDiscountMoney: '',
      modifyDiscountMoney1: '',
      discountTypeOps: [{text: '折扣',value: 3},{text: '减免',value: 2}],
      payTypeOps: [{text: '微信',value: 31},{text: '支付宝',value: 30},{text: '刷卡',value: 32},{text: '现金',value: 0}],
      noteTypeOps: [{text: '纸质发票',value: 0}],
      billTypeOpts:[{text: '全部缴清',value: 0},{text: '部分缴费',value: 1}],
      selectedTotalOpts:[{text: '全部缴清',value: "min"},{text: '部分缴费',value: "max"}],
      noteForm:{
        moneyType: 31,
        invoiceType: 0,
        invoiceNumber: '',
        payUserId: '',
        payUserName:'',
        payUserPhone: ''
      },
      width1: 100,
      editMoneyDialogVisible: false,
      payFeeDialogVisible: false,
      width: 200,
      form: {
        chargeBillId: undefined,
        billRemarks: ''
      },
      detailItem: {},
      billData: [],
      showModify: false,
      footerShow: false,
      showPayUser: false,
      dialogVisible: false,
      // 修改的金额
      modifyMoney: '',
      operationLogList: [],
      updateButtonFlag: false,
      vloading: true,
      userParams: {
        searchUrl: getOwnerListURL,
        request: {
          text: "ownerName",
        },
        response: {
          value: "id",
          text: "name",
        },
      },
      selectFlag: false
    }
    },
    computed: {
        resortedBill(){
            // 账单开始时间最小的先交掉 这里要把上一步给的账单重新排序一下
            return this.selectBillList.sort(
                (p,n)=> {
                  if(!(p.billingStartTime == n.billingStartTime)){
                   return new Date(p.billingStartTime).getTime() - new Date(n.billingStartTime).getTime()
                  }else{
                    return Number(p.chargeBillId)-Number(n.chargeBillId)
                  }
                }
            )
        },
        preBillInfo(){
            return this.resortedBill.reduce((p,n)=>{
                if(!p.ended && p.restMoney.toNumber()>=n.billMoney){
                    return {
                        index:p.index + 1 ,
                        restMoney:p.restMoney.minus(n.billMoney),
                        totalFullBillMoney:p.totalFullBillMoney.plus(n.billMoney),
                        totalFullLateFee:n.lateFreeVisible == 1 ? p.totalFullLateFee.plus(n.lateFee):p.totalFullLateFee,
                        totalFullTotalMoney:p.totalFullTotalMoney.plus(n.totalMoney),
                        totalFullDiscountMoney:p.totalFullDiscountMoney.plus(n.discountMoney)
                    }
                }else{
                return {...p,ended:true}
                }
            },{
                index:-1,//交满的账单下标，
                restMoney:new Big(this.prebillMoney || 0),//除去完整结算的账单剩余的金额
                totalFullBillMoney:new Big(0), //完整结算的账单总金额（包含违约金和折扣）
                totalFullLateFee:new Big(0), //完整结算的账单违约金总金额,
                totalFullTotalMoney:new Big(0),
                totalFullDiscountMoney:new Big(0),
                ended:false
            })
        },
        halfBillInfo(){
       if(this.preBillInfo.index == this.resortedBill.length -1 || Number(this.prebillMoney) == 0 ||this.preBillInfo.restMoney.toNumber() == 0 ){
         return{
          halfBill:null,
          percent:0,
          BillDays:0
         }
       }
       const halfBill = this.resortedBill[this.preBillInfo.index+1]
       //未交满账单的可交百分比
       const percent = this.preBillInfo.restMoney.div(halfBill.billMoney).times(100)
       const BillDays = (new Date(halfBill.billingEndTime.split(" ")[0]).getTime() - new Date(halfBill.billingStartTime.split(" ")[0]))/1000/60/60/24 + 1
       return {
          halfBill,
          percent,
          BillDays
       }
     },
     partBillTotalNumber(){
       return this.halfBillInfo.halfBill ? this.preBillInfo.index + 2  :this.preBillInfo.index + 1
     },
     totalPreBillMoney(){
       if(this.halfBillInfo.halfBill){
        const minDay = Math.floor(this.halfBillInfo.BillDays*this.halfBillInfo.percent/100)
        const isFullDay = minDay === this.halfBillInfo.BillDays*this.halfBillInfo.percent/100
        const minEndDay = moment(new Date( new Date(this.resortedBill[this.preBillInfo.index + 1 ].billingStartTime.split(" ")[0]).getTime() 
          + (minDay-1)*1000*60*60*24)).format('YYYY-MM-DD')
        const maxEndDay = isFullDay? minEndDay: moment(new Date(new Date(this.resortedBill[this.preBillInfo.index + 1 ].billingStartTime.split(" ")[0]).getTime() 
          + minDay*1000*60*60*24)).format('YYYY-MM-DD')

        //未缴满账单的最终账单金额（折扣前）
        const minHalfBillMoney = new Big(this.halfBillInfo.halfBill.billMoney).times(new Big(minDay).div(this.halfBillInfo.BillDays))
        const maxHalfBillMoney =  new Big(this.halfBillInfo.halfBill.billMoney).times(new Big(minDay).add(1).div(this.halfBillInfo.BillDays))

        //后端实际用到的比例
         const minRate = new Big(minHalfBillMoney.toFixed(2)).div(this.halfBillInfo.halfBill.billMoney)
         const maxRate = new Big(maxHalfBillMoney.toFixed(2)).div(this.halfBillInfo.halfBill.billMoney)

        // 历史优惠
        const minDiscountMoney = new Big(this.halfBillInfo.halfBill.discountMoney).times(minRate).add(this.preBillInfo.totalFullDiscountMoney)
        const maxDiscountMoney = new Big(this.halfBillInfo.halfBill.discountMoney).times(maxRate).add(this.preBillInfo.totalFullDiscountMoney)
        //违约金
        const minLateFee =this.halfBillInfo.halfBill.lateFreeVisible  == 1 ? new Big(this.halfBillInfo.halfBill.lateFee).times(minRate).add(this.preBillInfo.totalFullLateFee):this.preBillInfo.totalFullLateFee
        const maxLateFee =  this.halfBillInfo.halfBill.lateFreeVisible == 1 ?new Big(this.halfBillInfo.halfBill.lateFee).times(maxRate).add(this.preBillInfo.totalFullLateFee):this.preBillInfo.totalFullLateFee
     
        //优惠金额等于不打折金额 * （10-折扣）/10（或1） + 减免（或0）
        const minDiscount = (this.preBillInfo.totalFullBillMoney.add(new Big(new Big(minDay).div(this.halfBillInfo.BillDays).toFixed(2)).times( this.halfBillInfo.halfBill.billMoney))).times((this.discountType == 3 &&this.modifyDiscount1)?(10 - this.modifyDiscount1)/10: 0).add((this.discountType == 2 &&this.modifyDiscountMoney1)?this.modifyDiscountMoney1: 0).toFixed(2)
        const maxDiscount = (this.preBillInfo.totalFullBillMoney.add(new Big(new Big(minDay).add(1).div(this.halfBillInfo.BillDays).toFixed(2)).times(this.halfBillInfo.halfBill.billMoney))).times((this.discountType == 3 &&this.modifyDiscount1)?(10 - this.modifyDiscount1)/10:0).add((this.discountType == 2 &&this.modifyDiscountMoney1)?this.modifyDiscountMoney1: 0).toFixed(2)
       //要交费总金额（交满的账单总金额[包含违约金和折扣] + 未交满的账单缴费部分[包含违约金和折扣] -优惠金额）
        const minMoney = new Big(this.preBillInfo.totalFullBillMoney.add(minHalfBillMoney).toFixed(2)).minus(minDiscount)
        const maxMoney = isFullDay
          ? minMoney
          : new Big(this.preBillInfo.totalFullBillMoney.add(maxHalfBillMoney.toFixed(2)).minus(maxDiscount))
        // 折扣前总账单金额 = 当前要交的总金额(折扣前) + 修改金额产生的历史优惠金额 - 违约金 
        const minTotalMoney = this.preBillInfo.totalFullBillMoney.add(minHalfBillMoney.toFixed(2)).minus(minLateFee.toFixed(2)).add(minDiscountMoney.toFixed(2))
        const maxTotalMoney = this.preBillInfo.totalFullBillMoney.add(maxHalfBillMoney.toFixed(2)).minus(maxLateFee.toFixed(2)).add(maxDiscountMoney.toFixed(2))
          return {
              minDay,
              minMoney,
              maxMoney,
              minEndDay,
              minDiscount,
              maxDiscount,
              maxEndDay,
              minHalfBillMoney,
              maxHalfBillMoney,
              minLateFee,
              maxLateFee,
              minDiscountMoney,
              maxDiscountMoney,
              minTotalMoney,
              maxTotalMoney
            }
       }else if(this.preBillInfo.index >= 0){
          //刚好全部是满账单

          //要交费金额等于不打折金额 - 优惠金额
          const minDiscount = this.preBillInfo.totalFullBillMoney.times((this.discountType == 3 &&this.modifyDiscount1)?(10 - this.modifyDiscount1)/10:0).add((this.discountType == 2 &&this.modifyDiscountMoney1)?this.modifyDiscountMoney1: 0).toFixed(2)
          const maxDiscount = minDiscount
          const minEndDay  = moment(this.resortedBill[this.preBillInfo.index].billingEndTime).format('YYYY-MM-DD')
          const minMoney = this.preBillInfo.totalFullBillMoney.minus(minDiscount)
          const maxMoney = minMoney
          const maxEndDay = minEndDay

        const minTotalMoney = this.preBillInfo.totalFullTotalMoney
        const maxTotalMoney =  minTotalMoney
        const minLateFee = this.preBillInfo.totalFullLateFee
        const maxLateFee =  minLateFee
        const minDiscountMoney =this.preBillInfo.totalFullDiscountMoney
        const maxDiscountMoney = minDiscountMoney

        return {
           minDay: 0,
           minMoney,
           maxMoney,
           minDiscount,
           maxDiscount,
           minEndDay,
           maxEndDay,
           minTotalMoney,
           maxTotalMoney,
           minLateFee,
           maxLateFee,
           minDiscountMoney,
           maxDiscountMoney,
         }
       } else{
         return{
          minDay: 0,
          minMoney: null,
          maxMoney: null ,
          minEndDay: null,
          maxEndDay: null,
          minDiscount: 0,
          maxDiscount: 0,
          minTotalMoney:0,
          maxTotalMoney:0,
          minLateFee:0,
          maxLateFee:0,
          minDiscountMoney:0,
          maxDiscountMoney:0,
         }
       }
     },
    // 优惠后的小计
    subTotal () {
      // console.log(this.detailItem.totalMoney, this.detailItem.lateFee, this.detailItem.paidMoney, this.modifyMoney)
      // return (parseFloat(this.detailItem.totalMoney) + parseFloat(this.detailItem.lateFee) - parseFloat(this.detailItem.paidMoney) - parseFloat(this.modifyMoney || 0)).toFixed(2)
      // totalMoney 账单金额 paidMoney 已付金额 lateFee 原违约金额 discountMoney 原优惠 modifyMoney 修改优惠
      // return (parseFloat(this.detailItem.totalMoney) + parseFloat(this.detailItem.lateFee) - parseFloat(this.detailItem.paidMoney) - parseFloat(this.modifyMoney || 0)).toFixed(2)
      const intTotalMoney = parseInt((this.detailItem.totalMoney || 0) * 100)
      const intLateFee = parseInt((this.detailItem.lateFee || 0) * 100)
      const intPaidMoney = parseInt((this.detailItem.paidMoney || 0) * 100)
      const intModifyMoney = parseInt((this.modifyMoney || 0) * 100)
      return ((intTotalMoney + intLateFee - intPaidMoney - intModifyMoney) / 100).toFixed(2)
    },
    // 输入框最大的值
    maxInput () {
      const intTotalMoney = parseInt((this.detailItem.totalMoney || 0) * 100)
      const intLateFee = parseInt((this.detailItem.lateFee || 0) * 100)
      const intPaidMoney = parseInt((this.detailItem.paidMoney || 0) * 100)
      // return Number(parseFloat(this.detailItem.totalMoney) + parseFloat(this.detailItem.lateFee) - parseFloat(this.detailItem.paidMoney))
      return (intTotalMoney + intLateFee - intPaidMoney) / 100
    },
    showOrgName () {
      return this.$store.state.baseConfig.docConfig.ORG_NAME || ''
    },
    //账单金额
    selectTotalMoney() {
      return (this.selectBillList.reduce((a,b) =>{ return a + b.totalMoney},0)).toFixed(2)
    },
    //违约金
    selectLateFee() {
      let lateFeeList = this.selectBillList.filter((item)=>{return item.lateFreeVisible == 1})
      return (lateFeeList.reduce((a,b) =>{ return a + b.lateFee},0)).toFixed(2)
    },
    //历史优惠金额
    selectDiscountMoney() {
      return (this.selectBillList.reduce((a,b) =>{ return a + parseFloat(b.discountMoney)},0)).toFixed(2)
    },
    //已付金额
    selectPaidMoney() {
      return (this.selectBillList.reduce((a,b) =>{ return a + b.paidMoney},0)).toFixed(2)
    },
    //修改金额本次优惠金额,
    todayDiscountMoney() {
      //折扣
      if(this.discountType==3){
        return (this.selectTotalMoney*((10 - this.modifyDiscount||0) / 10)).toFixed(2)
      } else {
        //减免
        return ((parseFloat(this.modifyDiscountMoney)||0).toFixed(2)* this.selectBillList.length).toFixed(2)
      }
    },
    //应收总金额
    selectSubTotal() {
      // =账单总金额+违约金总金额-折扣*账单总金额-历史优惠总金额
      let result = (parseFloat(this.selectTotalMoney) + parseFloat(this.selectLateFee) - parseFloat(this.todayDiscountMoney) - parseFloat(this.selectDiscountMoney)).toFixed(2)
      if(result<0){
        this.$message('应收总金额不允许为负数，请修改折扣')
      }
      return result
    },
    selectTotal() {
      return (this.selectSubTotal-this.selectPaidMoney).toFixed(2)
    },
    maxModifyInput() {
      let result = this.selectBillList.map((item)=>{
        return (parseFloat(item.totalMoney) - parseFloat(item.discountMoney)).toFixed(2)
      })
      let minMoney = Math.min(...result)
      if(minMoney<0){
        return 0.00
      } else {
        return minMoney
      }
    },
    //缴费输入金额限制
    maxModifyInput1() {
      return this.selectTotalMoney - this.selectDiscountMoney
      // return this.selectBillList.reduce((a,b) =>{ return a + parseFloat(b.billMoney)},0)
    },
    //缴费本次优惠金额,
    todayDiscountMoney1() {
      //折扣
      if(this.discountType==3){
        return (this.selectTotalMoney*((10 - this.modifyDiscount1||0) / 10)).toFixed(2)
      } else {
        //减免
        return (parseFloat(this.modifyDiscountMoney1)||0).toFixed(2)
      }
    },
    //应收总金额
    selectSubTotal1() {
      // =账单总金额+违约金总金额-折扣*账单总金额-历史优惠总金额
      let result = (parseFloat(this.selectTotalMoney) + parseFloat(this.selectLateFee) - parseFloat(this.todayDiscountMoney1) - parseFloat(this.selectDiscountMoney)).toFixed(2)
      if(result<0&& this.billType != 1){
        // console.log(result,'fffff')
        this.$message('应收总金额不允许为负数，请修改折扣')
      }
      return result
    },
    selectTotal1() {
      return (this.selectSubTotal1-this.selectPaidMoney).toFixed(2)
    },
    },
    mounted () {
        
    },
    watch:{
        "totalPreBillMoney.minMoney"(val){
            if(Number(val) == 0){
            this.selectedTotal = "max"
            }else if(this.totalPreBillMoney.minMoney == this.totalPreBillMoney.maxMoney){
            this.selectedTotal = "min"
            }
        }
    },
    methods: {
        userChange(val) {
        if(val){
            this.noteForm.payUserName = val.userName
            this.noteForm.payUserPhone = val.mobileNum
            this.selectFlag = true
        } else {
            this.selectFlag = false
        }
        },
        //缴纳
        async confirmPay() {
            if(this.billType == 1){
                // console.log( this.totalPreBillMoney[this.selectedTotal+'Money'].toFixed(2))
                if(this.discountType==2 && Number(this.totalPreBillMoney[this.selectedTotal+'Money'].toFixed(2)) < 0 ){
                this.$message('应收总金额不允许为负数，请修改折扣!')
                    return
                }
            }
            if (!this.noteForm.payUserName||!this.noteForm.payUserPhone) {
                this.$message('请搜索缴费人或填写缴费人信息！')
                return
            } else if (this.noteForm.payUserPhone&&!/^(1)\d{10}$/.test(this.noteForm.payUserPhone)) {
                this.$message('缴费人手机号格式不正确！')
                return
            } else if (this.noteForm.invoiceNumber&&!/\d{8}/.test(this.noteForm.invoiceNumber)) {
                this.$message('发票号码格式不正确！')
                return
            } if (this.discountType==2 && this.selectTotal1<0) {
                this.$message('应收总金额不允许为负数，请修改折扣!')
                return
            } 
            // console.log(this.totalPreBillMoney.minEndDay,'this.totalPreBillMoney');
            // let isEndDay = new Date(new Date(this.totalPreBillMoney.minEndDay).getTime() + 86400000).getDate() === 1
            // console.log(isEndDay,"isEndDay");
            if(this.billType == 1 && !!this.halfBillInfo.halfBill && this.halfBillInfo.halfBill && this.totalPreBillMoney[this.selectedTotal+'HalfBillMoney'].toNumber() !== 0){
                let modifyBillList = this.selectBillList.map((item)=>{
                    return item.chargeBillId
                })
                const res = await this.$axios.post(checkPartBillURL,{
                        partMoney : this.prebillMoney,
                        billIdList: modifyBillList,
                        partBillId : this.halfBillInfo.halfBill.chargeBillId,
                        communityId: this.communityId,
                        partBillingEndTime : this.totalPreBillMoney[this.selectedTotal+'EndDay'],
                        partBillMoney:  Number(new Big(this.totalPreBillMoney[this.selectedTotal+'HalfBillMoney']).toFixed(2)),
                        type:this.selectedTotal == "min"
                            ? this.totalPreBillMoney.minMoney == this.totalPreBillMoney.maxMoney
                                ? 0
                                : 1
                            : 2
                })
                if(res.status != 100){
                    this.$message(res.msg)
                    return
                }
            }
                // this.payFeeDialogVisible = false
                this.$emit("closePayFeeDialog")
                let modifyMsg = `确认缴纳账单？`
                let modifyOk = await this.$confirm(modifyMsg, { title: '提示' })
                if (modifyOk) {
                    this.addOrder()
                }
        
        },
        addOrder() {
        let modifyBillList = this.selectBillList.map((item)=>{
            return item.chargeBillId
        })
        let _this = this
        let data = {
            billIdList: modifyBillList,
            communityId: this.communityId,
            discountType: this.discountType,
            houseId: this.houseId,
            invoiceInfo: {
                invoiceNumber: this.noteForm.invoiceNumber,
                invoiceType: 0
            },
            moneyType: this.noteForm.moneyType,
            orderMoney: parseFloat(this.selectTotal1), //应付金额
            orderMoneyDiscount: parseFloat(this.todayDiscountMoney1), //订单本次优惠
            orderMoneyTotal: parseFloat(this.selectTotalMoney) + parseFloat(this.selectLateFee) - parseFloat(this.selectDiscountMoney), //订单金额=账单金额+违约金-历史优惠
            orgId: this.detailItem.orgId,
            payCategory: 0, //个人支付
            payUserId: this.noteForm.payUserId,
            payUserName: this.noteForm.payUserName,
            payUserPhone: this.noteForm.payUserPhone
        }
        if(!this.noteForm.invoiceNumber){
            delete data.invoiceInfo
        }
        if(this.noteForm.payUserId){
            delete data.payUserName
            delete data.payUserPhone
        } else {
            delete data.payUserId
        }
        if((this.discountType==3 && !this.modifyDiscount1)||(this.discountType==2 && !this.modifyDiscountMoney1)){
            delete data.discountType
        }
        //部分缴费
        if(this.billType == 1 ){
            data.billIdList = this.resortedBill.map((item)=>{
                return item.chargeBillId
            }).slice(0,!!this.halfBillInfo.halfBill ? this.preBillInfo.index+2 : this.preBillInfo.index + 1)
            data.orderMoney = this.totalPreBillMoney[this.selectedTotal+'Money'].toFixed(2)
            data.orderMoneyDiscount = this.totalPreBillMoney[this.selectedTotal + 'Discount']
            data.orderMoneyTotal = this.totalPreBillMoney[this.selectedTotal + 'Money'].add(this.totalPreBillMoney[this.selectedTotal + 'Discount']).toFixed(2) 
            if( this.preBillInfo.index < this.selectBillList.length - 1){
                if(this.halfBillInfo.halfBill){
                    data.partBillId = this.halfBillInfo.halfBill.chargeBillId
                    data.partBillingEndTime = this.totalPreBillMoney[this.selectedTotal+'EndDay']
                    data.type = this.selectedTotal == "min"
                    ? this.totalPreBillMoney.minMoney== this.totalPreBillMoney.maxMoney
                    ? 0
                    : 1
                    :2
                    data.partMoney = this.prebillMoney
                    data.partBillMoney = Number(new Big( this.totalPreBillMoney[this.selectedTotal+'HalfBillMoney']).toFixed(2))
                }else {
                    // 完整账单 无需拆分
                }
            }
        
        }
        this.$axios({
            method: 'POST',
            url: addOrderURL,
            data: data
        }).then(res => {
            if (res.status === 100) {
                _this.batchPayOrder(res.data.orderId)
            }
        })
        },
        batchPayOrder(orderId) {
            let _this = this
            this.$axios({
                method: 'PUT',
                url: batchPayOrderURL + `?orderIdsList=${orderId}`
            }).then(res => {
                if (res.status === 100) {
                    _this.$message.success('缴费成功')
                    // _this.$router.go(-1)
                    this.selectFlag = false
                    this.noteForm={
                        moneyType: 31,
                        invoiceType: 0,
                        invoiceNumber: '',
                        payUserId: '',
                        payUserName:'',
                        payUserPhone: ''
                    }
                    this.modifyDiscount1 = undefined
                    this.modifyDiscountMoney1 = ''
                    this.discountType = 3
                    this.vloading = true
                    this.cancelPayFeeDialog()
                    _this.$emit('getTableList')
                }
            })
        },
        cancelPayFeeDialog() {
          this.modifyDiscount1 = undefined
          this.modifyDiscountMoney1 = ''
          this.prebillMoney = 0
          this.billType = 0
          this.modifyDiscount = undefined
          this.modifyDiscountMoney = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-bill-dialog-content{
    display: flex;
    align-items: center;
    padding-top: 10px;
    .edit-bill-tips{
    flex: 0 0 200px;
    text-align: right;
    }
    .edit-bill-dialog-input{
    flex: 1;
    text-align: left;
    padding-left: 20px;
    display: flex;
    .edit-bill-text{
        font-size: 14px;
    }
    }
}
//   ::v-deep.discount{
//       padding-top: 10px;
//       .col2-item{
//          .label{
//             line-height: 40px;
//          }
//           .text{
//               display: flex;
//           }
//       }
//   }
//   ::v-deep .title {
//   font-size: 20px;
// }

// .require{
//   color: red;
//   padding-right: 5px;
// }
.edit-bill-dialog-content ::v-deep { 
   .el-radio__inner{
    display:none
  }
  .el-radio__label{
    padding:0
  }
  .el-radio {
    padding:0;
    margin-right:0
  }
}
</style>
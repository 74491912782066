<template>
  <div class="house-data-wrapper">
      <form-panel :hasHeader="false">
        <div class="header-button">
          <!-- 配置右上角操作按钮 -->
          <slot name="headerSlot">
            <!-- 返回按钮 -->
            <v-button text="返回" @click="previous"></v-button>
          </slot>
        </div>
        <div class="leftCard">
          <el-card class="house" shadow="always">
            <div class="header" slot="header">
              <div class="header-title">房屋信息 <span>{{roomStatus}}</span></div>
              <v-button type="text" @click="$router.push({path:'/home/validHouseForm',query:{id:$route.query.roomId}})">编辑</v-button>
            </div>
            <col2-detail>
                <el-form-item style="margin-bottom:0" label="房号" span=22 label-width='60px'>{{standardHouseNum}}</el-form-item>
                <div class="bottom">
                  <div>
                    <el-form-item class="owners" label="业主" label-width='60px'  style="margin-right:300px">
                      <div v-for="(item,index) in ownerList" :key="`${item.userId}+${index}}`">
                        <span style="margin-right:5px">{{item.userName}}</span>
                        <span style="margin-right:10px">{{`(${sexMap[item.sex]})`}}</span>
                        <span>{{item.mobileNum.substring(0,3) + '****' + item.mobileNum.substring(7)}}</span>
                      </div>
                    </el-form-item>
                  </div>
                  <div class="house-area-type">
                    <el-form-item class="building-area" style="margin-bottom:0" label="建筑面积" label-width='90px'>{{buildingArea}}㎡</el-form-item>
                    <el-form-item class="house-type"  label="房屋类型" label-width='90px'>{{houseTypeMap[houseType]}}</el-form-item>
                  </div>
                </div>
            </col2-detail>
          </el-card>

          <el-card class="bill">
            <div class="header" slot="header">
              <div>
                未缴账单
                <v-select class="order-select"  v-model="payRoomId" :options="houseInfoList"></v-select>
                <!-- <span v-if="billsLength > 0"> {{`(${billsLength}项共￥${billMoneySum})`}}</span> -->
              </div>
              <div>
                <span class="all-money" v-if="billsLength > 0"> 合计：{{billMoneySum}}元</span>
                <v-button v-if="billsLength > 0" style="margin-left:12px" type="text" @click="payFee">收缴</v-button>
              </div>
            </div>
            <billTable ref="billTable" v-if="flag" :userIds="userIds" @getAllPrice="getAllPrice"></billTable>

          </el-card>

          <el-card class="work-order">
            <div class="header" slot="header">
              <div>
                <span>未完成工单</span>
                <span v-if="workOrderTableData.length">（{{workOrderTableData.length}}单）</span>
              </div>
              <v-button type="text"  @click="submitOrder">提单</v-button>
            </div>
            <div>
              <table-panel
                :headers="workOrderHeaders"
                :tableData="workOrderTableData"
                maxHeight="240"
                :tabStyle="tabStyle"
                :hasOperateColumn="false"
                >
                <!--<template #operateSlot="scope">-->
                <!--  <div class="operate">-->
                <!--    <v-button type="text"-->
                <!--              @click="handleNewWorkOrder(scope.row)">处理</v-button>-->
                <!--  </div>-->
                <!--</template>-->
              </table-panel>
            </div>
          </el-card>

        </div>
        <div class="rightCard">
          <el-card>
              <div class="header" slot="header">
                <span>成员信息（{{userDetailList.length}}人）</span>
                <v-button type="text" @click="add">新增</v-button>
              </div>
            <el-card  v-if="userIds.length" shadow="never">
              <div class="user-type" v-for="(userType,index) in userTypeList" :key="`${index}+${userType}}`">
                <div class="user-type-title">{{userTypeMap[userType]}}</div>
                <div class="user-detail-item" v-for="(userDetail,index) in userDetailList.filter(item=>item.userType === userType)" :key="`${userDetail.id}+${index}}`">
                  <div class="user-info">
                    <div class="icon">
                      <el-avatar icon="el-icon-user-solid" :size="30" :src="userDetail.headPicName"></el-avatar>
                      <img v-if="userDetail.sex === 1" class="sex" style="width: 14px; height: 14px" src="./images/sex_man.png" />
                      <img v-if="userDetail.sex === 2" class="sex" style="width: 14px; height: 14px" src="./images/sex_woman.png" />
                    </div>
                    <div class="main-info">
                      <span class="user-name">{{userDetail.userName}}</span>
                      <span class="phone">{{userDetail.mobileNum.substring(0,3) + "****" + userDetail.mobileNum.substring(7)}}</span>
                      <div>登记时间：{{userDetail.intime}}</div>
                    </div>
                  </div>
                  <el-dropdown @command="handleCommand($event,userDetail)" trigger="click">
                    <span class="el-dropdown-link">
                      <i class="el-icon-more" style="color:#2077FF"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="look">查看</el-dropdown-item>
                      <el-dropdown-item command="edit">编辑</el-dropdown-item>
                      <el-dropdown-item v-if="userDetail.userSource == 1" command="leave">搬离</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                </div>
              </div>
            </el-card>
            <div v-else class="empty-wrapper">
              <img src="./images/empty.png" />
              <span v-text="emptyText"></span>
            </div>
          </el-card>
        </div>
      </form-panel>

      <!-- 成员信息对话框 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        class="dialog"
        :width="dialogWidth"
        >
        <col2-detail>
          <!-- 查看 -->
          <col2-block  v-if="command === 'look'">
            <col2-item  class="look" label="姓名" :text="userDetail.userName" />
            <col2-item  class="look" label="婚姻状态" :text="maritalStatusMap[userDetail.isMarry]" />
            <col2-item  class="look" label="手机号" :text="userDetail.mobileNum" />
            <col2-item  class="look" label="政治面貌" :text="userDetail.politicsStatus" />
            <col2-item  class="look" label="性别" :text="sexMap[userDetail.sex]"/>
            <col2-item  class="look" label="职业" :text="userDetail.career" />
            <col2-item  class="look" label="身份" :text="userTypeMap[userDetail.userType]" />
            <col2-item  class="look" label="证件类型" :text="cardTypeMap[userDetail.cardType]" />
            <col2-item  class="look" label="Mail" :text="userDetail.emailAddress" />
            <col2-item  class="look" label="证件号" :text="userDetail.cardNum" />
            <col2-item  class="look" label="移动端注册" :text="userDetail.userSource === 1?'已注册':'未注册'" />r
            <col2-item  class="look" label="出生日期" :text="userDetail.birthday?userDetail.birthday.split(' ')[0]:'' " />
            <col2-item  class="look" label="标签" :text="labelNames.join('、')" />
            <col2-item  class="look" label="籍贯" :text="userDetail.nativePlace" />
          </col2-block>
          <!-- 编辑 -->
          <col2-block v-if="command === 'edit' || command === 'add'">
            <col2-item class="name"  style="margin-bottom:10px;" label="姓名" :required='required'>
              <v-input  v-model="form.userName" :disabled="editIsDisable" :width="200"></v-input>
            </col2-item>
            <col2-item style="margin-bottom:10px" :span="12" label="婚姻状态">
              <v-select v-model="form.isMarry" :options="maritalStatusOpts" :width="200"></v-select>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="手机号" :required='required'>
              <v-input v-model="form.mobileNum" :disabled="editIsDisable || command === 'edit'" :width="200"></v-input>
            </col2-item>
            <col2-item  style="margin-bottom:10px" label="政治面貌" :text="userDetail.politicsStatus">
              <v-select v-model="form.politicsStatus" :options="politicsStatusOps" :width="200"></v-select>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="性别" :required='required'>
              <el-radio v-model="form.sex" label="1" :disabled="editIsDisable">男</el-radio>
              <el-radio v-model="form.sex" label="2" :disabled="editIsDisable">女</el-radio>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="职业">
              <v-input v-model="form.career" :width="200"></v-input>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="身份" :required='required'>
              <v-select v-model="form.userType" :options="userTypeOps" :width="200"></v-select>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="证件类型">
              <v-select v-model="form.cardType" :options="cardTypeOpts" :disabled="editIsDisable" :width="200"></v-select>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="Mail">
              <v-input v-model="form.emailAddress" :width="200"></v-input>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="证件号">
              <v-input v-model="form.cardNum" :disabled="editIsDisable" :width="200"></v-input>
            </col2-item>
            <col2-item style="margin-bottom:10px;height:40px" label="移动端注册" :text="userDetail.userSource === 1?'已注册':'未注册'" />
            <col2-item style="margin-bottom:10px" class="picker" label="出生日期" :text="userDetail.birthday?userDetail.birthday.split(' ')[0]:'' ">
              <el-date-picker class="date-picker"  type="date" v-model="form.birthday" value-format="yyyy-MM-dd"  placeholder="选择日期" :disabled="editIsDisable"></el-date-picker>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="标签">
              <el-select v-model="form.labelIds" filterable  multiple placeholder="请选择" v-loadmore="loadmore" :width="200">
                <el-option
                  v-for="item in labelIds"
                  :key="item.value"
                  :label="item.labelName"
                  :value="item.labelId"
                ></el-option>
              </el-select>
            </col2-item>
            <col2-item style="margin-bottom:10px" label="籍贯">
              <v-select v-model="form.nativePlace" :options="nativePlaceOps" :width="200"></v-select>
            </col2-item>
          </col2-block>
          <!-- 搬离 -->
          <col2-block style="text-align:left" v-if="command === 'leave'">
            {{`请确认住户${userDetail.userName}搬离${standardHouseNum}`}}
          </col2-block>
        </col2-detail>
        <div v-if="show" slot="footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 提单 -->
      <el-dialog
        title="新增工单"
        class="add-order"
        :visible.sync="orderDialogVisible"
        width="width">
        <order ref="orderDialog" :communityId="communityId" :houseId="roomId" :taskInfoAddress="standardHouseNum" @closeOrderDialog="closeOrderDialog"></order>
        <div slot="footer">
          <v-button type="" text="取消" @click="orderDialogVisible = false"></v-button>
          <v-button style="margin-left:12px" class="confirm-button"  @click="saveOrder" text="保存"></v-button>
        </div>
      </el-dialog>

      <!-- 费用收缴 -->
      <el-dialog
        title="费用收缴"
        :visible.sync="payFeeDialogVisible"
        width="67%">
        <payFeeDialog ref="payFeeDialog" :houseId="payRoomId" :communityId="communityId" :selectBillList="selectBillList" @getTableList="getTableList" @closePayFeeDialog="closePayFeeDialog"></payFeeDialog>
        <div slot="footer">
          <v-button type="" text="取消" @click="cancelPayFeeDialog"></v-button>
          <v-button style="margin-left:12px" class="confirm-button"  @click="confirmPay" text="缴纳"></v-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import {Card,Tag,Descriptions,DescriptionsItem,Image,Avatar,Dialog,Radio,DatePicker} from 'element-ui'
import { Col2Detail, Col2Block, Col2Item,CheckboxPlus,TablePanel } from 'components/bussiness'
import {getRoomAndOwnerInfoURL,getUserDetailByRoomIdURL,delStructUserInfoUrl,multiSelectUrl,updateSubmitUrl,createHouseInfoUrl,createSubmitUrl,updateHouseInfoUrl,selectUnpaidChargeBillListByHouseIdURL,selectRoomIdsByUserIdsURL,getListURL} from './api'
import {houseTypeOps, houseTypeMap,roomStatusOps,maritalStatusOpts,politicsStatusOps,userTypeOps,userTypeMap,maritalStatusMap,cardTypeMap,cardTypeOpts,orderStatusMap} from './map'
import nativePlaceData from '@/../public/static/json/province.json'
import _ from 'lodash'
import Vue from 'vue';
import billTable from './billTable.vue'
import order from './order.vue'
import payFeeDialog from './payFeeDialog'
import { mobileRegular } from '@/common/regular'
Vue.directive('loadmore', {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function() {
      /*
      * scrollHeight 获取元素内容高度(只读)
      * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
      * clientHeight 读取元素的可见高度(只读)
      * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
      * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
      */
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if(CONDITION) {
        binding.value();
      }
    });
  }
})
export default {
    name:'houseInfo',
    components:{
      "el-card":Card,
      "el-tag":Tag,
      "el-descriptions":Descriptions,
      "el-descriptions-item":DescriptionsItem,
      "el-image":Image,
      "el-avatar":Avatar,
      "el-dialog":Dialog,
      "el-radio":Radio,
      "el-date-picker":DatePicker,
      Col2Detail,
      Col2Block,
      Col2Item,
      CheckboxPlus,
      billTable,
      order,
      payFeeDialog,
      TablePanel
    },
    data(){
      return{
        tabStyle:{
          width:"100%",
          height:"240px"
        },
        orderStatusMap,
        dialogWidth:'60%',
        emptyText: '暂无成员信息',
        flag:false,
        houseTypeMap,
        userTypeOps,
        cardTypeOpts,
        maritalStatusOpts,
        userTypeMap,
        maritalStatusMap,
        cardTypeMap,
        politicsStatusOps,
        roomId:'',
        standardHouseNum:'',
        buildingArea:'',
        houseType:'',
        ownerList:[],
        sexMap:['未知','男','女'],
        roomStatus:'',
        userTypeList:[],
        userDetailList:[],
        dialogVisible:false,
        userDetail:{},
        labelNames:[],
        show:true,
        command:'',
        title:'',
        required:true,
        form: {
          assetId:"",
          userName: '',
          userType:undefined,
          mobileNum: '',
          sex: '',
          birthday: '',
          phoneNum: '',
          career: '',
          politicsStatus: undefined,
          nativePlace: undefined,
          isMarry: '',
          cardType: undefined,
          cardNum: '',
          emailAddress: '',
          userSource: undefined,
          labelIds:[],
          communityId:'',
          userId:'',
          realnameExamine:undefined
        },
        communityId:'',
        labelIds:[],
        labelId:'',
        nativePlaceOps:[],
        curPage:1,
        maxPage:'',
        editIsDisable:false,
        userIds:[],
        orderDialogVisible:false,
        billMoneySum:'',
        billsLength:'',
        payFeeDialogVisible:false,
        selectBillList:[],
        showError:false,
        showPhoneError:false,
        houseInfoList:[],
        payRoomId:undefined,
        workOrderHeaders:[
          {
            prop:'categoryName',
            label:'工单类型'
          },
          {
            prop:'username',
            label:'提单人'
          },
          {
            prop:'userMobile',
            label:'手机号'
          },
          {
            prop:'createTime',
            label:'提单时间'
          },
          {
            prop:'description',
            label:'工单内容'
          },
          {
            prop:'status',
            label:'处理状态',
            formatter(row){
              return orderStatusMap[row.status]
            }
          }
        ],
        workOrderTableData:[]
      }
    },
    created(){
      this.$setBreadcrumb({
          reset: true,
          breadcrumb: []
      })
      this.roomId = this.$route.query.roomId
      // this.payRoomId = this.$route.query.roomId + ''
      this.getUserDetailByRoomId()
      this.getRoomAndOwnerInfo()
      this.getNativePlaceData()
      this.getRoomList()
    },
    mounted(){
      this.$nextTick(()=>{
        if(this.userIds.length){
          this.selectRoomIdsByUserIds()
        }
      })
    },
    methods:{
      // 查询指定房号及业主信息
      getRoomAndOwnerInfo(){
        this.$axios.get(`${getRoomAndOwnerInfoURL}?roomId=${this.roomId}`).then(res=>{
          if(res.status === 100){
            let {standardHouseNum,buildingArea,houseType,ownerList,roomStatus,communityId} = res.data
            this.standardHouseNum = standardHouseNum
            this.buildingArea = buildingArea
            this.houseType = houseType
            this.roomStatus = roomStatusOps.find(item=>item.value == roomStatus).text
            this.ownerList = ownerList
            this.form.communityId= communityId
            this.communityId= communityId
            this.$forceUpdate()
          }
        })
      },
      // 获取指定房号下的所有成员详细信息
      getUserDetailByRoomId(){
        this.$axios.get(`${getUserDetailByRoomIdURL}?roomId=${this.roomId}`).then(res=>{
          this.flag = true
          if(res.status === 100) {
            // let {userType}
            let arr = []
            res.data.forEach(item=>{
              arr.push(item.userType)
            })
            arr = [...new Set(arr)]
            this.userTypeList = arr.sort()
            this.userDetailList = res.data
            this.userIds = res.data.map(item=>item.id)
            this.$forceUpdate()
          }
        })
      },
      // 成员搬离组织
      delStructUserInfo(){
        this.flag = false
         let params = {
            params: {
              orgId: this.userDetail.orgId,
              userId: this.userDetail.id
            }
          }
        this.$axios.get(delStructUserInfoUrl,params).then(async res=>{
          if(res.status === 100){
            this.dialogVisible = false
            this.$message.success(`住户${this.userDetail.userName}已搬离${this.standardHouseNum}`)
            setTimeout(()=>{
              this.getRoomAndOwnerInfo()
              this.getUserDetailByRoomId()
            },1000)
          }
        })
      },
      // 成员信息的操作
      handleCommand(command,userDetail){
        this.form = {
          assetId:"",
          userName: '',
          userType:undefined,
          mobileNum: '',
          sex: '',
          birthday: '',
          phoneNum: '',
          career: '',
          politicsStatus: undefined,
          nativePlace: undefined,
          isMarry: '',
          cardType: undefined,
          cardNum: '',
          emailAddress: '',
          userSource: undefined,
          labelIds:[],
          communityId:'',
          userId:'',
          realnameExamine:''
        }
        this.command = command
        this.userDetail = userDetail
        command === 'leave'?this.title = '搬离组织':this.title = '成员信息'
        // 查看
        if(command === 'look'){
          this.dialogVisible = true
          this.show = false
          this.dialogWidth = '40%'
          this.labelNames = userDetail.mlabels.map(item=>{
            return item.labelName
          })
        }
        // 编辑
        if(command === "edit"){
          this.dialogVisible = true
          this.show = true
          this.dialogWidth = '60%'
          Object.keys(this.form).forEach(key => {
            this.form[key] = userDetail[key] || this.form[key]
          })
          this.form.cardType = userDetail.cardType?userDetail.cardType:undefined
          this.form.isMarry = userDetail.isMarry + ''
          this.form.sex = userDetail.sex + ''
          this.form.userId = userDetail.id
          this.form.realnameExamine = userDetail.realnameExamine
          userDetail.isMarry === ''?this.form.isMarry=undefined:this.form.isMarry=userDetail.isMarry
          this.labelIds = userDetail.mlabels.map(item=>{
                return {labelId:item.labelId,labelName:item.labelName}
          })
          this.form.labelIds = userDetail.mlabels.map(item=>{
                return item.labelId
          })
          if(this.form.realnameExamine){
            this.editIsDisable = true
          }else{
            this.editIsDisable = false
          }
          this.multiSelect()
        }
        // 搬离
        if(command === "leave"){
          this.dialogVisible = true
          this.show = true
          this.dialogWidth = '30%'
        }
      },
      async confirm(){
        if(this.command === 'leave'){
          this.delStructUserInfo()
        }
        if(this.command === 'edit'){
          this.updateSubmit()

        }
        if(this.command === 'add'){
          this.createSubmit()
        }
      },
      // 获取籍贯列表
      getNativePlaceData () {
        if (nativePlaceData) {
          this.nativePlaceOps = [
            {
              text: '请选择',
              value: undefined
            }
          ]
        }
        nativePlaceData.name.map(item => {
          this.nativePlaceOps.push({
            text: item,
            value: item
          })
        })
      },
      // 获取用户标签列表
      multiSelect(curPage=1){
        let params = {
            params: {
              curPage:curPage,
              executeSearch:1,
              pageSize:8,
              maxPage:5,
              maxRow:81,
              row:20,
              nextPage:2
            }
          }
        this.$axios.get(multiSelectUrl,params).then(res=>{
          if(res.status === 100){
            // 拿到请求回来的所有的标签列表
            let newLabelIds = res.data.resultList.map(item=>{
              return {
                labelId:item.id,
                labelName:item.labelName
              }
            })
            this.maxPage = res.data.maxPage
            // 将成员信息详情中返回的标签  与  请求回来的所有的标签列表  进行合并
            this.labelIds.push(...newLabelIds)
            // 去除重复的标签
            this.labelIds = _.uniqWith(this.labelIds,_.isEqual)
          }
        })
      },
      loadmore(){
        if(this.curPage <= this.maxPage){
          this.curPage ++
          this.multiSelect(this.curPage)
        }
      },
      // 编辑
      async updateSubmit(){
        if(this.form.cardNum){
          if(!this.form.cardType){
            return this.$message.error('请先选择证件类型')
          }
        }
        await this.validateCardNum()
        if(this.showError) return this.$message.error('证件号码格式不正确')
        let data =  _.cloneDeep(this.form)
        data.labelIds = JSON.stringify(data.labelIds)

        if(!data.userName) return this.$message.warning('请填写姓名')
        if(!data.mobileNum) return this.$message.warning('请填写手机号')
        if(!data.userType) return this.$message.warning('请填写身份信息')
        if(!data.sex) return this.$message.warning('请填写性别')

        this.$axios({
          method:'PUT',
          url:updateSubmitUrl,
          headers:{'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'},
          data:this.$qs.stringify(data)
        }).then(res=>{
          if(res.status === 100) {
            this.dialogVisible = false
            this.$message.success(`住户${this.userDetail.userName}的信息修改成功`)
            // 修改关联房产
            this.updateHouseInfo()
          }
        })
      },
      // 点击 新增成员 按钮弹出对话框
      add(){
        this.title = '成员信息'
        this.dialogVisible = true
        this.show = true
        this.command = 'add'
        this.editIsDisable = false
        this.form = {
          assetId:"",
          userName: '',
          userType:undefined,
          mobileNum: '',
          sex: '',
          birthday: '',
          phoneNum: '',
          career: '',
          politicsStatus: undefined,
          nativePlace: undefined,
          isMarry: undefined,
          cardType: undefined,
          cardNum: '',
          emailAddress: '',
          userSource: undefined,
          labelIds:[],
          communityId:'',
          userId:'',
          realnameExamine:''
        }
        this.multiSelect()
      },
      // 确认添加成员
      async createSubmit(){
        if(this.form.cardNum){
          if(!this.form.cardType){
            return this.$message.error('请先选择证件类型')
          }
        }
        await this.validateCardNum()
        if(this.showError) return this.$message.error('证件号码格式不正确')
        await this.validatePhone()
        if(this.showPhoneError) return this.$message.error('手机号码格式不正确')
        let data =  _.cloneDeep(this.form)
        delete data.userId
        delete data.assetId
        if(!data.userName) return this.$message.warning('请填写姓名')
        if(!data.mobileNum) return this.$message.warning('请填写手机号')
        if(!data.userType) return this.$message.warning('请填写身份信息')
        if(!data.sex) return this.$message.warning('请填写性别')
        this.$axios({
          method:'POST',
          url:createSubmitUrl,
          data
        }).then(res=>{
          if(res.status == 100){
            this.form.userId = res.data
            this.createHouseInfo()
            this.dialogVisible = false
          }
        })
      },
      //  确认添加成员时需要 关联房产
      createHouseInfo(){
        let data = {
          communityId:this.communityId,
          userId:this.form.userId,
          roomId:this.roomId + '',
          isMessage:0,
          isPayment:0,
          userType:this.form.userType,
          houseType:this.houseType,
          rentStartDate: '',
          rentEndDate: '',
          personRoomRelationship: undefined,
          lesseeRelationship: undefined,
          personType: undefined,
          houseOwnerName: '',
          houseOwnerCardnum: '',
          houseOwnerMobilenum: '',
          userRelation: undefined,
          isOpenDoor: undefined,
          isDefault: undefined,
          isExamine: undefined,
          rejectReason: '',
          groupTeam: '',
          roomNumber: '',
          roomFullNumber: '',
          ownerState: '',
          rentWithdrawalDate: '',
          purchaseDate: ''
        }

        this.$axios({
          method:'POST',
          url:createHouseInfoUrl,
          data
        }).then(res=>{
          this.getRoomAndOwnerInfo()
          this.getUserDetailByRoomId()
        })
      },
      // 修改成员信息时 修改关联房产
      updateHouseInfo(){
        let data = {
          assetId:this.form.assetId,
          communityId:this.communityId,
          userId:this.form.userId,
          roomId:this.roomId,
          // isMessage:0,
          // isPayment:0,
          userType:this.form.userType,
          houseType:this.houseType,
          // rentStartDate: '',
          // rentEndDate: '',
          // personRoomRelationship: undefined,
          // lesseeRelationship: undefined,
          // personType: undefined,
          // houseOwnerName: '',
          // houseOwnerCardnum: '',
          // houseOwnerMobilenum: '',
          // userRelation: undefined,
          // isOpenDoor: undefined,
          // isDefault: undefined,
          isExamine: 1,
          // rejectReason: '',
          // groupTeam: '',
          // roomNumber: '',
          // roomFullNumber: '',
          // ownerState: '',
          // rentWithdrawalDate: '',
          // purchaseDate: '',
          isHouseHolder:0
        }

        this.$axios({
          method:'POST',
          url:updateHouseInfoUrl,
          data
        }).then(res=>{
          this.getRoomAndOwnerInfo()
          this.getUserDetailByRoomId()
        })
      },
      submitOrder(){
        this.orderDialogVisible = true
      },
      // 获取未缴账单总数和总价
      getAllPrice(val){
        let {billMoneySum,billsLength} = val
        this.billMoneySum = billMoneySum?billMoneySum:'0'
        this.billsLength = billsLength
      },
      previous(){
        this.$router.replace("/home/worktop")
      },
      // 点击收缴弹出对话框
      async payFee(){
        await this.selectUnpaidChargeBillListByHouseId()
        this.payFeeDialogVisible = true

      },
      cancelPayFeeDialog(){
        this.payFeeDialogVisible = false
      },
      confirmPay(){
        this.$refs.payFeeDialog.confirmPay()
      },
      closePayFeeDialog(){
        this.payFeeDialogVisible = false
      },
      selectUnpaidChargeBillListByHouseId(){
            this.$axios({
                method:"GET",
                url:selectUnpaidChargeBillListByHouseIdURL,
                params:{
                  houseId:this.payRoomId
                }
            }).then(res=>{
              if(res.status === 100){
                this.selectBillList = res.data

              }
            })
      },
      saveOrder(){
        console.log("saveOrder");
        this.$refs.orderDialog.save()
      },
      closeOrderDialog(){
        this.orderDialogVisible = false
        console.log(this.orderDialogVisible,"this.orderDialogVisible");
        this.getRoomList()
      },
      // 校验身份证
      validateCardNum(){
      //身份证
      let regExp1 = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      //军官证
      let regExp2 = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/
      //护照
      let regExp3 = /^([a-zA-z]|[0-9]){9}$/
      //驾驶证
      let regExp5 = /^\d{12}$/
      //港澳通行证
      let regExp6 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
      //台胞证
      let regExp7 = /^\d{8}$|^[a-zA-Z0-9]{10}$|^\d{18}$/
      //户口本
      let regExp8 = /^\d{9}$/
      //警官证
      let regExp9 = /^[0-9]{8,9}$/
      let showError = false
      if (this.form.cardNum && this.form.cardNum.length > 0) {
        if(this.form.cardType == 1) {
          if (!regExp1.test(this.form.cardNum)) {
            showError = true
          }
        } else if(this.form.cardType == 2) {
          if (!regExp2.test(this.form.cardNum)) {
            showError = true
          }
        } else if(this.form.cardType == 3) {
          if (!regExp3.test(this.form.cardNum)) {
            showError = true
          }
        } else if(this.form.cardType == 5) {
          if (!regExp5.test(this.form.cardNum)) {
            showError = true
          }
        } else if(this.form.cardType == 6) {
          if (!regExp6.test(this.form.cardNum)) {
            showError = true
          }
        } else if(this.form.cardType == 7) {
          if (!regExp7.test(this.form.cardNum)) {
            showError = true
          }
        } else if(this.form.cardType == 8) {
          if (!regExp8.test(this.form.cardNum)) {
            showError = true
          }
        } else if(this.form.cardType == 9) {
          if (!regExp9.test(this.form.cardNum)) {
            showError = true
          }
        }
      }
      if (showError) {
        this.showError = true
      } else {
        this.showError = false
      }
      },
      // 校验手机号
      validatePhone () {
      let regExp = mobileRegular
      if (this.form.mobileNum && this.form.mobileNum.length > 0) {
        if (!regExp.test(this.form.mobileNum)) {
          this.showPhoneError = true
        }else{
          this.showPhoneError = false
        }
      }
      },
      // 查询指定成员同项目的所有房号 包含车位、库房
      selectRoomIdsByUserIds(){
        this.$axios({
          method:"POST",
          url:selectRoomIdsByUserIdsURL,
          data:this.userIds
        }).then(res=>{
          if(res.status === 100){
            this.payRoomId = this.$route.query.roomId
            this.houseInfoList = res.data.map(item=>{
              let houseType = houseTypeMap[item.houseType]
              return {
                // text:`[${houseType}${item.blockName}苑${item.buildingNumber}幢${item.unit}单元${item.room}室]`,
                text:`[${houseType}]${item.roomNumName}`,
                value:item.roomNumId + ''
              }
            })
            this.payRoomId = this.$route.query.roomId + ''
          }
        })
      },
      getTableList(){
        this.$refs.billTable.changeTableList(this.payRoomId)
      },
      getRoomList(){
        this.$axios({
          method:'GET',
          url:getListURL,
          params:{
            roomId:this.roomId
          }
        }).then(res=>{
          if(res.status === 100){
            this.workOrderTableData = res.data
          }
        })
      },
      // 查看工单详情，处理工单
      handleNewWorkOrder(row){
        console.log(row,"row----");
        console.log('lookNewWorkOrder');
        let {pid,cid,fid,sid} = this.$route.query
        const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
        this.$router.push({
          name:'iframePanel',
          query:{
            pid:1,
            cid:3400,
            fid:94751,
            sid:947241,
            src:menuInfo.item.url,
            orderId:row.id,
            isReplace: 1
            // tableData:[]
          }
        })
      }
    },
    watch:{
      userIds(){
         if(this.userIds.length){
          this.selectRoomIdsByUserIds()
        }
        this.$forceUpdate()
      },
      payRoomId(){
        this.$refs.billTable.changeTableList(this.payRoomId)
      }
    }
}
</script>

<style scoped lang="scss">
.house-data-wrapper{
  width: 100%;
  .form-panel-container{
    .content{
      .el-form{
        .header-button {
          position: absolute;
          top: 20px;
          right: 20px;
          :not(:last-child) {
            margin-right: 8px;
          }
        }
        .leftCard{
          display: flex;
          flex-direction: column;
          width: 60%;
          height: 1220px;
          margin-right: 20px;
          .el-card{
            margin-bottom: 10px;
            .header{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .header-title{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                span{
                  display: inline-block;
                  width: 40px;
                  height: 20px;
                  font-weight: 500;
                  text-align: center;
                  line-height: 20px;
                  margin-left: 5px;
                  background-color: #E3F7ED;
                  color: #14B968;
                  border-radius: 3px;
                }
              }

            }
          }
          .house{
            max-height: 400px;
          }
          .bill{
            flex-grow:1;
            .header{
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
            }
          }
          .work-order{
            height: 400px;
            .header{
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .rightCard{
          width: 35%;
          height: 1210px;
          .el-card{
            .header{
              display: flex;
              justify-content: space-between;
              span{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
              }
            }
            .el-card .user-type{
              .user-type-title{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
              }
              .user-detail-item{
                display: flex;
                justify-content: space-between;
                margin: 20px 0 10px 0;
                .user-info{
                  display: flex;
                  position: relative;
                  .icon{
                    margin-right: 10px;
                    .sex{
                      position: absolute;
                      top: 0;
                      left: 20px;
                    }
                  }
                  .main-info{
                    .user-name{
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 20px;
                      color: #323233;
                      margin-right: 12px;
                    }
                    .phone{
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 20px;
                      color: #606266;
                    }
                    div{
                      font-family: 'PingFang SC';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 20px;
                      color: #909399;
                    }
                  }
                }
              }
            }
            .empty-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 156px;
              height: 180px;
            }
          }
          }

        }
      }
    }
  }
}

::v-deep .el-form-item{
  display: inline-block;
  .el-form-item__label{
    width: 50px;
  }
}
.bottom{
  display: flex;
  .house-area-type{
    width: 200px;
  }
}
::v-deep .el-form{
  display: flex;
}
::v-deep .el-card__header{
  border: none;
}
::v-deep .el-dropdown{
  height: 30px;
  cursor: pointer;
}
.el-dropdown-menu {
  margin-top: 0;
}
.date-picker{
  width: 200px;
}
::v-deep .el-card__body{
  .el-card{
    max-height: 1040px;
    .el-card__body{
      height: 1000px;
      overflow-y: auto;
    }
  }
}
::v-deep .picker {
  .col2-item{
    .label{
      // margin-right: 20px;
    }
    .text{
      .el-date-editor{
        .el-input__suffix{
          right: 25px;
        }
      }
    }
  }
}
::v-deep .date-picker{
  input{
    box-sizing:border-box;
    width: 200px;
    // padding-right: 0;
  }
}
::v-deep .col2-item {
    .text{
      text-align: left;
    }
}

// ::v-deep .look{
//   .col2-item {
//     .text{
//       text-align: left;
//     }
//   }
// }
::v-deep .rightCard{
  .el-card{
    height: 1210px;
  }
}
::v-deep .house{
  max-height: 400px;
  padding-right: 10px;
  .el-card__body{
    height: 270px;
    overflow: auto;
    margin-bottom: 10px;
    padding: 0 20px;
  }
}
::v-deep .order-select{
  font-weight: 400;
  font-size: 14px;
  width: 240px;
  height: 32px;
  margin-left: 12px;
  .el-select{
    width: 240px;
    height: 32px;
    .el-input{
      width: 240px;
        height: 32px;
      .el-input__inner{
        width: 240px;
        height: 32px;
      }
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
}
.all-money{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #606266;
}
</style>
